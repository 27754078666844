<template>
  <div>
    <component :is="currentTemplate" v-bind="$props" />
  </div>
</template>

<script>
import Template1 from '@/templates/TemplateOne.vue';
import Template2 from '@/templates/TemplateTwo.vue';
import Template3 from '@/templates/TemplateThree.vue';
import Template4 from '@/templates/TemplateFour.vue';
import Template5 from '@/templates/TemplateFive.vue';
import Template6 from '@/templates/TemplateSix.vue';
import Template7 from '@/templates/TemplateSeven.vue';
import Template8 from '@/templates/TemplateEight.vue';

export default {
  props: {
    name: String,
    jobTitle: String,
    phoneNumber: String,
    emailAddress: String,
    mainColor: String,
    secondaryColor: String,
    selectedIcons: Array,
    socialLinks: Object,
    iconLinks: Object,
    profileImage: String,
    profileImageHref: String,
    descriptionBackground: String,
    borderColor: String,
    signatureWidth: Number,
    showProfileImage: Boolean,
    profileImageSize: Number,
    profileImageShape: Number,
    fontFamily: String,
    fontSize: String,
    additionalFields: Array,
    template: String,
    logoImage: String,
    logoImageHref: String,
    showLogo: Boolean,
    disclaimerWidth: Boolean,
    logoSize: Number,
    socialIconColor: String,
    socialIconSize: Number,
    addonsColor: String,
    bannerWidth: Number,
    feedback: Object,
    link: {
      type: Object,
      default: () => ({})
    },
    banner: {
      type: Object,
      default: () => ({})
    },
    disclaimer: {
      type: String,
      default: ''
    },
    customIcons: {
      type: Array,
      default: () => []
    },
  },
  computed: {
    currentTemplate() {
      switch (this.template) {
        case 'template1':
          return Template1;
        case 'template2':
          return Template2;
        case 'template3':
          return Template3;
        case 'template4':
          return Template4;
        case 'template5':
          return Template5;
        case 'template6':
          return Template6;
        case 'template7':
          return Template7;
        case 'template8':
          return Template8;
        default:
          return Template1;
      }
    }
  },
  methods: {
    convertRgbToHex(color) {
      if (!color.startsWith('rgb')) return color;
      const rgb = color.match(/\d+/g);
      if (!rgb) return color;

      const hex = rgb
        .map(num => parseInt(num, 10).toString(16).padStart(2, '0'))
        .join('');
      return `#${hex}`;
    },
    processHtml(html) {
      return html.replace(/rgb\((\d+), (\d+), (\d+)\)/g, (_, r, g, b) => {
        return this.convertRgbToHex(`rgb(${r}, ${g}, ${b})`);
      });
    }
  }
};
</script>
