<template>
  <div>
    <div class="header">
      <h2>Social Media</h2>
      <div class="button-group">
        <button 
          :class="['toggle-color-button', { 'active': isWhiteIcons }]" 
          @click="toggleIconColor">
          Change Icon Color
        </button>
        <button v-if="selectedIcons.length > 0" class="reset-button" @click="resetSelection">Reset Selection</button>
      </div>
    </div>
    <div class="icon-grid">
      <div v-for="icon in icons.concat(customIcons.map(c => c.name))" :key="icon" class="icon-item">
        <label :class="[{ selected: isSelected(icon) }, { 'white-icon-bg': isWhiteIcons }]">
          <input type="checkbox" :value="icon" @change="toggleIcon(icon)">
          <div v-if="loadingStates[icon]" class="loader-container">
            <LoaderApp :isLoading="loadingStates[icon]" />
          </div>
          <img
            v-else
            :src="getIconLink(icon)"
            :alt="icon"
            class="icon"
            @load="handleImageLoad(icon)"
            @error="handleImageError(icon)"
          />
          <span>{{ formatIconName(icon) }}</span>
        </label>
        <input
          v-if="isSelected(icon)"
          type="text"
          v-model="links[icon]"
          placeholder="Enter profile URL"
          @input="updateLink(icon)"
          class="profile-link-input"
        />
      </div>
    </div>
    <div class="add-custom-icon">
      <h3>Add Custom Social Media Icon</h3>
      <input type="text" v-model="newIconName" placeholder="Icon Name" class="custom-icon-input"/>
      <input type="text" v-model="newIconUrl" placeholder="Icon Image URL" class="custom-icon-input"/>
      <button @click="addCustomIcon" class="add-button">Add Icon</button>
    </div>
  </div>
</template>

<script>
import { reactive } from 'vue';
import LoaderApp from './LoaderApp.vue';

export default {
  props: {
    selectedIcons: Array,
    socialLinks: Object,
    customIcons: Array,
    iconLinks: Object,
    isWhiteIcons: Boolean,
  },
  components: {
    LoaderApp,
  },
  data() {
    return {
      icons: [
        'behance', 'coffee', 'diaspora', 'dribbble', 'facebook', 'github',
        'gitlab', 'gitter', 'heart', 'instagram', 'linkedin', 'mastodon', 'menu',
        'ok', 'reddit', 'skype', 'slack', 'stackoverflow', 'telegram', 'twitter',
        'vk', 'whatsapp', 'xing', 'youtube',
      ],
      links: reactive({ ...this.socialLinks }),
      newIconName: '',
      newIconUrl: '',
      loadingStates: reactive({}),
    };
  },
  mounted() {
    this.icons.concat(this.customIcons.map(c => c.name)).forEach(icon => {
      this.loadingStates[icon] = true;
      const img = new Image();
      img.src = this.getIconLink(icon);
      img.onload = () => this.handleImageLoad(icon);
      img.onerror = () => this.handleImageError(icon);
    });
  },
  methods: {
    isSelected(icon) {
      return this.selectedIcons.includes(icon);
    },
    toggleIcon(icon) {
      if (this.isSelected(icon)) {
        this.$emit('update-icons', this.selectedIcons.filter((i) => i !== icon));
      } else {
        this.$emit('update-icons', [...this.selectedIcons, icon]);
      }
    },
    updateLink(icon) {
      this.$emit('update-links', { ...this.links, [icon]: this.links[icon] });
    },
    formatIconName(icon) {
      return icon.charAt(0).toUpperCase() + icon.slice(1);
    },
    resetSelection() {
      this.$emit('update-icons', []);
      this.$emit('update-links', {});
    },
    addCustomIcon() {
      if (this.newIconName && this.newIconUrl) {
        this.$emit('add-custom-icon', { name: this.newIconName, url: this.newIconUrl });
        this.newIconName = '';
        this.newIconUrl = '';
      }
    },
    toggleIconColor() {
      this.$emit('toggle-icon-color');
    },
    getIconLink(icon) {
      return this.iconLinks[icon] || this.customIcons.find(c => c.name === icon)?.url || '';
    },
    handleImageLoad(icon) {
      this.loadingStates[icon] = false;
    },
    handleImageError(icon) {
      console.error(`Failed to load icon: ${icon}`);
      this.loadingStates[icon] = false;
    },
  },
  watch: {
    socialLinks: {
      handler(newLinks) {
        Object.assign(this.links, newLinks);
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.loader-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.header {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #eee;
  transition: border-color 0.5s;
}

.header h2 {
  font-size: 1.5em;
  color: #555;
  padding-bottom: 10px;
  transition: color 0.5s;
}

.header button {
  margin-bottom: 10px;
}

.button-group {
  display: flex;
  align-items: center;
}

.icon-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 0.5rem;
}

.icon-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem;
}

h2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

label {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.2rem;
  border: 1px solid transparent;
  border-radius: 8px;
  width: 64px;
  height: 64px;
  transition: background-color 0.3s, border-color 0.3s, box-shadow 0.3s;
  cursor: pointer;
  position: relative;
}

label.selected {
  background-color: #e0e0e0;
  border-color: #333;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

label.white-icon-bg {
  background-color: #cccccc;
}

label.selected.white-icon-bg {
  background-color: #999999;
  border-color: #666;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.icon {
  width: 32px;
  height: 32px;
  margin-bottom: 0.5rem;
}

input[type="checkbox"] {
  display: none;
}

.profile-link-input {
  margin-top: 0.5rem;
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-sizing: border-box;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.profile-link-input:focus {
  border-color: #333;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  outline: none;
}

.reset-button {
  padding: 0.5rem 1rem;
  background-color: transparent;
  color: #333;
  border: 2px solid #333;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.reset-button:hover {
  background-color: #333;
  color: #fff;
}

.toggle-color-button {
  padding: 0.5rem 1rem;
  background-color: #f0f0f0;
  color: #333;
  border: 2px solid #333;
  border-radius: 8px;
  cursor: pointer;
  margin-right: 10px;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.toggle-color-button.active {
  background-color: #333;
  color: #fff;
}

.toggle-color-button:hover {
  background-color: #555;
  color: #fff;
}

.add-custom-icon {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.custom-icon-input {
  margin: 0.5rem 0;
  padding: 0.5rem;
  width: 100%;
  max-width: 400px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-sizing: border-box;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.custom-icon-input:focus {
  border-color: #333;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  outline: none;
}

.add-button {
  padding: 0.5rem 1rem;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.add-button:hover {
  background-color: #555;
}

.dark-mode .header {
  border-color: #555;
}

.dark-mode .header h2 {
  color: #ddd;
}

.dark-mode label {
  background-color: #444;
  color: #ddd;
}

.dark-mode label.selected {
  background-color: #555;
  border-color: #ddd;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
}

.dark-mode .profile-link-input {
  background-color: #333;
  color: #ddd;
  border-color: #555;
}

.dark-mode .profile-link-input:focus {
  border-color: #ddd;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
  outline: none;
}

.dark-mode .reset-button {
  color: #ddd;
  border-color: #ddd;
}

.dark-mode .reset-button:hover {
  background-color: #ddd;
  color: #333;
}

.dark-mode .toggle-color-button {
  background-color: #555;
  color: #ddd;
  border-color: #ddd;
}

.dark-mode .toggle-color-button.active {
  background-color: #333;
  color: #fff;
}

.dark-mode .toggle-color-button:hover {
  background-color: #ddd;
  color: #333;
}

.dark-mode .custom-icon-input {
  background-color: #333;
  color: #ddd;
  border-color: #555;
}

.dark-mode .custom-icon-input:focus {
  border-color: #ddd;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
}

.dark-mode .add-button {
  background-color: #555;
}

.dark-mode .add-button:hover {
  background-color: #777;
}
</style>
