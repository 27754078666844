<template>
  <div :class="['app', { 'dark-mode': isDarkMode }]">
    <div v-if="isMobile && navOpen" class="overlay" @click="toggleNav"></div>
    <main class="content">
      <div v-if="isLoading" class="loader-container">
        <Loader :isLoading="isLoading" />
      </div>
      <section v-if="!isLoading && !isPreview" class="editor">
        <button class="hamburger" @click="toggleNav" v-if="isMobile">
          <i class="fas fa-bars"></i>
        </button>

        <nav class="top-nav" :class="{ 'hidden': isMobile && !navOpen, 'visible': isMobile && navOpen }">
          <router-link to="/signature-templates" class="nav-tab">
            <i class="fas fa-clipboard-list"></i> Templates
          </router-link>
          <router-link to="/basic-info" class="nav-tab">
            <i class="fas fa-info-circle"></i> Basic Info
          </router-link>
          <router-link to="/signature-options" class="nav-tab">
            <i class="fas fa-cogs"></i> Options
          </router-link>
          <router-link to="/social-media" class="nav-tab">
            <i class="fas fa-share-alt"></i> Social Media
          </router-link>
          <router-link to="/signature-addons" class="nav-tab">
            <i class="fas fa-puzzle-piece"></i> Addons
          </router-link>
          <button class="nav-tab code-button" @click="showSaveModal = true">
            <i class="fas fa-code"></i> Save
          </button>
          <button class="nav-tab code-button" v-if="isDevMode" @click="showCodeModal">
            <i class="fas fa-code"></i> Code
          </button>
        </nav>

        <router-view
          v-if="!isLoading"
          :formData="formData"
          :selectedIcons="formData.selectedIcons"
          :socialLinks="formData.socialLinks"
          :iconLinks="currentIconLinks"
          :profileImage="formData.profileImage"
          :additionalFields="formData.additionalFields"
          :customIcons="formData.customIcons"
          :logoImage="formData.logoImage"
          :showLogo="showLogo"
          :disclaimerWidth="disclaimerWidth"
          :logoSize="logoSize"
          :selectedTemplate="formData.selectedTemplate"
          :link="formData.link"
          :banner="formData.banner"
          :disclaimer="formData.disclaimer"
          :addonsColor="addonsColor"
          :bannerWidth="bannerWidth"
          :isWhiteIcons="formData.isWhiteIcons"
          @update="updateFormData"
          @update-image="updateProfileImage"
          @update-logo-image="updateLogoImage"
          @update-icons="updateSelectedIcons"
          @add-custom-icon="addCustomIcon"
          @update-links="updateSocialLinks"
          @textColorChange="updateTextColor"
          @mainColorChange="updateMainColor"
          @secondaryColorChange="updateSecondaryColor"
          @descriptionBackgroundChange="updateDescriptionBackground"
          @borderColorChange="updateBorderColor"
          @signatureWidthChange="applySignatureWidth"
          @socialIconColorChange="updateSocialIconColor"
          @toggleProfileImage="toggleProfileImage"
          @profileImageSizeChange="applyProfileImageSize"
          @profileImageShapeChange="applyProfileImageShape"
          @fontFamilyChange="applyFontFamily"
          @fontSizeChange="applyFontSize"
          @add-field="addAdditionalField"
          @remove-field="removeAdditionalField"
          @update-field-value="updateFieldValue"
          @template-selected="updateSelectedTemplate"
          @toggle-logo="toggleLogo"
          @toggle-disclaimer="toggleDisclaimer"
          @logoSizeChange="applyLogoSize"
          @update-profile-image-href="updateProfileImageHref"
          @update-logo-image-href="updateLogoImageHref"
          @socialIconSizeChange="applySocialIconSize"
          @show-modal="showModal = true"
          @update-link="updateLink"
          @update-banner="updateBanner"
          @update-disclaimer="updateDisclaimer"
          @addonsColorChange="updateAddonsColor"
          @bannerWidthChange="updateBannerWidth"
          @update-feedback="updateFeedback"
          @toggle-icon-color="toggleIconColor"
        ></router-view>
      </section>
      <section v-if="!isLoading" :class="['preview', { 'isPreview': isPreview }]">
        <img class="logo" src="../assets/images/logo.png" alt="logo">
        <div class="email-client">
          <div class="title-bar">
            <div class="buttons">
              <div class="close"></div>
              <div class="minimize"></div>
              <div class="maximize"></div>
            </div>
            <div class="title">Email Preview</div>
            <div class="actions">
              <div class="reset-settings" @click="confirmResetSettings">
                <i class="fas fa-undo-alt"></i>
              </div>
              <div class="dark-mode-toggle" @click="toggleDarkMode">
                <i :class="isDarkMode ? 'fas fa-sun' : 'fas fa-moon'"></i>
              </div>
            </div>
          </div>
          <div class="toolbar">
            <i class="fas fa-reply"></i>
            <i class="fas fa-reply-all"></i>
            <i class="fas fa-share"></i>
            <i class="fas fa-trash"></i>
            <i class="fas fa-envelope-open-text"></i>
          </div>
          <div class="email-header">
            <div class="email-from">From: gSignature Generator</div>
            <div class="email-to">To: {{ previewEmail || 'recipient@example.com' }}</div>
            <div class="email-subject">Subject: {{ formData.subject || "Email Subject" }}</div>
            <div class="email-date">Date: {{ formData.date || "Jan 1, 2024" }}</div>
          </div>
          <div class="email-body">
            <signature-preview
              ref="signaturePreview"
              v-if="hasFormData"
              :name="formData.name"
              :jobTitle="formData.jobTitle"
              :phoneNumber="formData.phoneNumber"
              :emailAddress="formData.emailAddress"
              :mainColor="formData.mainColor"
              :secondaryColor="formData.secondaryColor"
              :signatureWidth="formData.signatureWidth"
              :selectedIcons="formData.selectedIcons"
              :socialLinks="formData.socialLinks"
              :iconLinks="currentIconLinks"
              :profileImage="formData.profileImage"
              :profileImageHref="formData.profileImageHref"
              :descriptionBackground="formData.descriptionBackground"
              :borderColor="formData.borderColor"
              :showProfileImage="formData.showProfileImage"
              :profileImageSize="formData.profileImageSize"
              :profileImageShape="formData.profileImageShape"
              :fontFamily="formData.fontFamily"
              :fontSize="formData.fontSize"
              :additionalFields="formData.additionalFields"
              :template="formData.selectedTemplate"
              :customIcons="formData.customIcons"
              :logoImage="formData.logoImage"
              :logoImageHref="formData.logoImageHref"
              :showLogo="formData.showLogo"
              :disclaimerWidth="formData.disclaimerWidth"
              :logoSize="formData.logoSize"
              :socialIconColor="formData.socialIconColor"
              :socialIconSize="formData.socialIconSize"
              :link="formData.link"
              :banner="formData.banner"
              :disclaimer="formData.disclaimer"
              :addonsColor="formData.addonsColor"
              :bannerWidth="formData.bannerWidth"
              :feedback="formData.feedback"
            ></signature-preview>
          </div>
        </div>
      </section>
    </main>

    <!-- Modal -->
    <ModalSave :show="showSaveModal" :formData="formData" @close="showSaveModal = false" @send-email="handleSendEmail"></ModalSave>
    <ModalAdd :show="showModal" @close="showModal = false" @add-field="handleAddField"></ModalAdd>
    <ModalCode :show="showCodeModalFlag" :code="generatedCode" @close="showCodeModalFlag = false"></ModalCode>
    <ModalReset :show="showResetModal" @close="showResetModal = false" @confirm="handleConfirmReset"></ModalReset>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import SignaturePreview from '../components/SignaturePreview.vue';
import ModalAdd from '../components/ModalAdd.vue';
import ModalCode from '../components/ModalCode.vue';
import ModalReset from '../components/ModalReset.vue';
import ModalSave from '../components/ModalSave.vue';
import { saveAs } from 'file-saver';
import Loader from '../components/LoaderApp.vue';
import axios from 'axios';
import { BASE_URL } from '../config';

export default {
  name: 'App',
  components: {
    SignaturePreview,
    ModalAdd,
    ModalCode,
    ModalReset,
    ModalSave,
    Loader,
  },
  data() {
    const domain = window.location.origin;
    const basePath = this.basePath.endsWith('/') ? this.basePath : `${this.basePath}/`;
    return {
      previewEmail: '',
      isLoading: false,
      entryTime: null,
      clickCount: 0,
      changeCount: 0,
      showHeader: true,
      showModal: false,
      showCodeModalFlag: false,
      showResetModal: false,
      showSaveModal: false,
      isDarkMode: false,
      navOpen: false,
      isPreview: false,
      isSettings: false,
      isDev: false,
      generatedCode: '',
      formData: {
        name: 'John Williams',
        jobTitle: 'Customer Success Specialist',
        phoneNumber: '+48 123 456 789',
        emailAddress: 'john@mail.com',
        mainColor: '#000000',
        secondaryColor: '#000000',
        descriptionBackground: 'transparent',
        borderColor: '#000000',
        signatureWidth: 500,
        showProfileImage: true,
        profileImageSize: 120,
        profileImageShape: 50,
        fontSize: 'medium',
        fontFamily: 'Arial, sans-serif',
        showLogo: true,
        disclaimerWidth: false,
        logoSize: 120,
        socialIconSize: 24,
        socialIconColor: 'transparent',
        addonsColor: '#000000',
        bannerWidth: 600,
        link: {
          name: '',
          url: '',
        },
        banner: {
          url: '',
          href: '',
        },
        feedback: {
          question: 'How nice was my reply?',
          likeLink: `${domain}${basePath}positive`,
          dislikeLink: `${domain}${basePath}negative`,
        },
        disclaimer: '',
        profileImage: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:24:12.887Z_0f3e3e48-9211-4084-97ef-768b44153def',
        profileImageHref: '',
        logoImage: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:23:58.465Z_bc9ad3e0-8cda-4138-8464-8150ce17de68',
        selectedTemplate: 'template1',
        logoImageHref: '',
        additionalFields: [],
        selectedIcons: ['facebook', 'instagram', 'linkedin'],
        socialLinks: {
          facebook: 'https://www.facebook.com',
          instagram: 'https://www.instagram.com',
          linkedin: 'https://www.linkedin.com',
        },
        customIcons: [],
        isWhiteIcons: false,
        iconLinksBlack: {
          behance: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:26:24.377Z_18428c82-ebcb-4a63-9f0f-6beb2ee11026',
          coffee: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:26:44.908Z_55f58ebd-5bdd-44f3-9f18-0fd518a4665f',
          diaspora: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:27:10.648Z_3f65a699-3c98-4f6a-9f2f-9da1f40fd0f7',
          dribbble: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:27:31.862Z_82835eef-437c-4000-995b-b32a02c5eb9a',
          facebook: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:27:59.871Z_1e1e577b-79c3-405b-93e1-4676857c4b9a',
          github: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:28:13.756Z_8b87990c-c0f1-4964-a151-a23e84ce2095',
          gitlab: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:28:34.932Z_8ac01c5d-61e7-490b-b273-25773e4dd25f',
          gitter: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:28:47.947Z_ead866ff-fa7c-4a14-a629-77d6c8c6cd70',
          heart: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:28:59.427Z_a87699b4-054c-46f0-b41d-ffa3a8a4a3fe',
          instagram: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:29:15.830Z_fd078727-f6fe-414e-8226-28ae4cabd534',
          linkedin: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:29:28.491Z_84cc5ce2-d45a-4995-ac2b-239bc40b72b5',
          mastodon: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:29:43.677Z_ad5e7c92-866e-4f43-a897-57984e948e74',
          menu: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:29:59.613Z_1f61506f-3b54-45b1-b74f-54be2b4aa27a',
          ok: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:30:13.068Z_3d9d81dd-e78a-4e4e-886d-3be4d19093eb',
          reddit: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:30:27.268Z_a0d90373-f846-494f-881d-c6a9750eb8ee',
          skype: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:30:50.676Z_eb8a6fc1-ad4f-4d1a-af17-e9eeae0dd4e8',
          slack: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:31:06.146Z_405327e7-aa1f-4a71-8001-deaea5af08d8',
          stackoverflow: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:31:23.538Z_7bc449b2-08de-4fac-bbc0-37e9797977da',
          telegram: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:31:37.960Z_759375eb-50b8-472e-aa92-f0b1cfd05a8b',
          twitter: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:31:54.611Z_fd67cf43-78cf-4bdc-8e59-eb655d07bdd7',
          vk: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:32:09.724Z_a702319f-633e-452d-8f58-8b81a38a7838',
          whatsapp: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:32:25.512Z_62b025d8-9080-4cf8-b167-2aeae6f90255',
          xing: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:32:42.328Z_4904923c-b82a-4ed2-be58-c55994aadaa9',
          youtube: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:32:54.794Z_9889edae-4a1d-4d77-b039-ed78e756ce99',
        },
        iconLinksWhite: {
          behance: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:35:04.528Z_89f82a62-b75f-4a10-bf64-1dd104e7971f',
          coffee: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:35:21.783Z_f43f83f2-e276-489d-8303-8a8d0cc50952',
          diaspora: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:35:36.238Z_bd6531f3-b3bd-4f58-810d-4e471cd96825',
          dribbble: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:35:54.183Z_2c4ebb8e-c6f7-4709-906b-7d8206ee8145',
          facebook: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:36:07.326Z_733c7249-76cf-47cd-81d3-dc01fd7c16e2',
          github: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:36:19.479Z_4bb18b73-1e2e-42b4-bf41-deca918720b2',
          gitlab: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:36:43.815Z_7f8619a8-dc0a-48e2-9f21-45485b0b9208',
          gitter: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:36:58.102Z_a50622d2-1935-4b1e-9f93-b602d6c43e97',
          heart: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:37:12.618Z_2b59cbe8-8282-4dd8-b093-8bbd02e8b5dc',
          instagram: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:37:34.722Z_44df56f6-3301-4c62-bcd6-df220d761715',
          linkedin: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:37:51.133Z_d9298197-8198-4847-919b-24e5c169aa98',
          mastodon: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:38:19.128Z_3194a01d-1934-40a9-8358-a14fea059373',
          menu: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:38:34.502Z_cdb2cc08-8b5d-4091-894d-3c3110d74182',
          ok: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:38:47.805Z_3ac145d3-eea4-4d63-ace4-d227bea6b652',
          reddit: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:39:02.420Z_2e146e92-4698-4c5c-a22b-e9fb8c24d9f7',
          skype: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:39:18.012Z_3e4ff1d6-5816-4731-a078-b35734845dd6',
          slack: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:39:34.053Z_50381480-4ffe-4856-bcab-f7fc4ed1f558',
          stackoverflow: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:39:54.077Z_9ac562de-4043-4285-940b-9c1b987c3184',
          telegram: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:40:13.045Z_e66c97a0-c527-48c5-9906-c794e54a880b',
          twitter: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:40:50.852Z_5911f23e-540c-41e5-bd8c-a285805e1b60',
          vk: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:41:07.102Z_47c8dd04-8004-4e4c-b2a9-0f0fc360f6b0',
          whatsapp: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:41:22.167Z_057d568f-0879-4e49-994d-48295440e8fa',
          xing: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:41:37.633Z_944dc88c-4d5e-4b63-a700-8ff42192d4db',
          youtube: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:41:51.250Z_68f28ef9-abab-4ce7-b2c4-b6d4c6e02b84',
        }
      }
    }
  },
  computed: {
    hasFormData() {
      return (
        this.formData.name ||
        this.formData.jobTitle ||
        this.formData.phoneNumber ||
        this.formData.emailAddress ||
        this.formData.additionalFields.length > 0
      );
    },
    isDevMode() {
      return this.$route.path.includes('/dev');
    },
    isMobile() {
      return window.innerWidth < 997;
    },
    currentIconLinks() {
      return this.formData.isWhiteIcons ? this.formData.iconLinksWhite : this.formData.iconLinksBlack;
    }
  },
  setup() {
    const router = useRouter();
    return { basePath: router.options.history.base };
  },
  methods: {
    updateCurrentDate() {
      const currentDate = new Date();
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      this.formData.date = currentDate.toLocaleDateString('en-US', options);
    },
    checkIfPreview() {
      const currentPath = this.$route.path;
      this.isPreview = currentPath.includes('/preview');
    },
    checkIfDev() {
      const currentPath = this.$route.path;
      this.isDev = currentPath.includes('/dev');
    },
    checkIfSettings() {
      const currentPath = this.$route.path;
      this.isSettings = currentPath.includes('/settings');
    },
    async loadSettingsFromId(id) {
      this.isLoading = true;
      try {
        const url = `${BASE_URL}get-signature?id=${id}`;
        const response = await axios.get(url);
        if (response.status !== 200) {
          throw new Error('Failed to load settings');
        }
        const data = response.data;
        if (data.error) {
          console.error('Error from server:', data.error);
        } else {
          this.formData = { ...this.formData, ...data.settings };
          this.previewEmail = data.email;
        }
      } catch (error) {
        console.error('Error loading settings:', error);
      } finally {
        this.isLoading = false;
      }
    },
    toggleIconColor() {
      this.formData.isWhiteIcons = !this.formData.isWhiteIcons;
    },
    /*
    trackUserSession(eventType) {
      const exitTime = Date.now();
      const timeSpent = exitTime - this.entryTime;

      const screenResolution = `${window.screen.width}x${window.screen.height}`;
      const deviceType = this.getDeviceType();
      const browserLanguage = navigator.language || navigator.userLanguage;
      const browserInfo = this.getBrowserInfo();

      const data = {
        entryTime: this.entryTime,
        exitTime,
        timeSpent,
        clickCount: this.clickCount,
        changeCount: this.changeCount,
        screenResolution,
        deviceType,
        browserLanguage,
        browserInfo,
        eventType,
      };

      axios.post(`${BASE_URL}track-user`, data)
    .catch(err => {
        console.error('Error tracking user session:', err);
    });
    },
    */
    registerClick() {
      this.clickCount++;
    },
    registerChange() {
      this.changeCount++;
    },
    getDeviceType() {
      const ua = navigator.userAgent;
      if (/tablet|ipad|playbook|silk/i.test(ua)) return 'tablet';
      if (/Mobile|Android|iP(hone|od)/i.test(ua)) return 'mobile';
      return 'desktop';
    },
    getBrowserInfo() {
      const userAgent = navigator.userAgent;
      if (/chrome|chromium|crios/i.test(userAgent)) return 'Chrome';
      if (/firefox|fxios/i.test(userAgent)) return 'Firefox';
      if (/safari/i.test(userAgent) && !/chrome|crios|chromium/i.test(userAgent)) return 'Safari';
      if (/opr\//i.test(userAgent)) return 'Opera';
      if (/edg/i.test(userAgent)) return 'Edge';
      if (/msie|trident/i.test(userAgent)) return 'Internet Explorer';
      return 'Unknown';
    },
    toggleNav() {
      this.navOpen = !this.navOpen;
      const topNav = document.querySelector('.top-nav');

      if (this.navOpen && topNav) {
        topNav.classList.remove('hidden');
        topNav.classList.add('visible');
        document.body.style.overflow = 'hidden';
        document.querySelector('.editor').style.overflow = 'hidden';
      } else if (topNav) {
        topNav.classList.remove('visible');
        topNav.classList.add('hidden');
        document.body.style.overflow = '';
        document.querySelector('.editor').style.overflow = '';
      }
    },
    handleResize() {
      const topNav = document.querySelector('.top-nav');
      if (this.isMobile && topNav) {
        this.navOpen = false;
        topNav.classList.add('hidden');
      }
    },
    updateFormData(data) {
      this.formData = {
        ...this.formData,
        ...data,
      };
    },
    addCustomIcon(icon) {
      this.formData.customIcons.push(icon);
    },
    updateProfileImage(imageUrl) {
      this.formData.profileImage = imageUrl;
    },
    updateSelectedIcons(icons) {
      this.formData.selectedIcons = icons;
    },
    updateSocialLinks(links) {
      this.formData.socialLinks = links;
    },
    updateTextColor(color) {
      this.formData.mainColor = color;
    },
    updateMainColor(color) {
      this.formData.mainColor = color;
    },
    updateSecondaryColor(color) {
      this.formData.secondaryColor = color;
    },
    updateDescriptionBackground(color) {
      this.formData.descriptionBackground = color;
    },
    updateBorderColor(color) {
      this.formData.borderColor = color;
    },
    updateSocialIconColor(color) {
      this.formData.socialIconColor = color;
    },
    applySignatureWidth(signatureWidth) {
      this.formData.signatureWidth = signatureWidth;
    },
    toggleProfileImage(showProfileImage) {
      this.formData.showProfileImage = showProfileImage;
    },
    applyProfileImageSize(profileImageSize) {
      this.formData.profileImageSize = profileImageSize;
    },
    applyProfileImageShape(profileImageShape) {
      this.formData.profileImageShape = profileImageShape;
    },
    updateLogoImage(imageUrl) {
      this.formData.logoImage = imageUrl;
    },
    toggleLogo(showLogo) {
      this.formData.showLogo = showLogo;
    },
    toggleDisclaimer(disclaimerWidth) {
      this.formData.disclaimerWidth = disclaimerWidth;
    },
    applyLogoSize(logoSize) {
      this.formData.logoSize = logoSize;
    },
    updateProfileImageHref(href) {
      this.formData.profileImageHref = href;
    },
    updateLogoImageHref(href) {
      this.formData.logoImageHref = href;
    },
    applySocialIconSize(size) {
      this.formData.socialIconSize = size;
    },
    applyFontFamily(fontFamily) {
      this.formData.fontFamily = fontFamily;
    },
    applyFontSize(fontSize) {
      this.formData.fontSize = fontSize;
    },
    addAdditionalField(fields) {
      if (fields) {
        this.formData.additionalFields = fields;
      } else {
        this.formData.additionalFields.push({
          label: `Text ${this.formData.additionalFields.length + 1}`,
          value: '',
          href: '',
          hrefType: 'url'
        });
      }
    },
    removeAdditionalField(index) {
      this.formData.additionalFields.splice(index, 1);
    },
    updateFieldValue(index, value) {
      this.formData.additionalFields[index].value = value;
    },
    updateSelectedTemplate(templateName) {
      this.formData.selectedTemplate = templateName;
    },
    toggleDarkMode() {
      this.isDarkMode = !this.isDarkMode;
    },
    saveSignature() {
      const template = this.$refs.signaturePreview.$el.innerHTML;
      const blob = new Blob([template], { type: 'text/html;charset=utf-8' });
      saveAs(blob, 'signature.html');
    },
    convertRgbToHex(color) {
      if (!color.startsWith('rgb')) return color;

      const rgb = color.match(/\d+/g);
      if (!rgb) return color;

      const hex = rgb
        .map((num) => parseInt(num, 10).toString(16).padStart(2, '0'))
        .join('');
      return `#${hex}`;
    },
    processHtml(html) {
      return html.replace(/rgb\((\d+), (\d+), (\d+)\)/g, (_, r, g, b) => {
        return this.convertRgbToHex(`rgb(${r}, ${g}, ${b})`);
      });
    },
    showCodeModal() {
      const rawHtml = this.$refs.signaturePreview.$el.innerHTML;
      this.generatedCode = this.processHtml(rawHtml);
      this.showCodeModalFlag = true;
    },
    handleAddField(field) {
      this.formData.additionalFields.push({
        label: field.label,
        value: field.value,
        href: field.href,
        hrefType: field.hrefType
      });
    },
    updateAddonsColor(color) {
      this.formData.addonsColor = color;
    },
    updateBannerWidth(width) {
      this.formData.bannerWidth = width;
    },
    updateLink(link) {
      this.formData.link = link;
    },
    updateBanner(banner) {
      this.formData.banner = banner;
    },
    updateDisclaimer(disclaimer) {
      this.formData.disclaimer = disclaimer;
    },
    updateFeedback(feedback) {
      this.formData.feedback = feedback;
    },
    handleConfirmReset() {
      this.resetSettings();
      this.showResetModal = false;
    },
    confirmResetSettings() {
      this.showResetModal = true;
    },
    resetSettings() {
      const domain = window.location.origin;
      const basePath = this.basePath.endsWith('/') ? this.basePath : `${this.basePath}/`;
      this.formData = {
        name: 'John Williams',
        jobTitle: 'Customer Success Specialist',
        phoneNumber: '+48 123 456 789',
        emailAddress: 'john@mail.com',
        mainColor: '#000000',
        secondaryColor: '#000000',
        descriptionBackground: 'transparent',
        borderColor: '#000000',
        signatureWidth: 500,
        showProfileImage: true,
        profileImageSize: 120,
        profileImageShape: 50,
        fontSize: 'medium',
        fontFamily: 'Arial, sans-serif',
        showLogo: true,
        disclaimerWidth: false,
        logoSize: 120,
        socialIconSize: 24,
        socialIconColor: 'transparent',
        addonsColor: '#000000',
        bannerWidth: 600,
        link: {
          name: '',
          url: ''
        },
        banner: {
          url: '',
          href: ''
        },
        feedback: {
          question: 'How nice was my reply?',
          likeLink: `${domain}${basePath}positive`,
          dislikeLink: `${domain}${basePath}negative`,
        },
        disclaimer: '',
        profileImage: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:24:12.887Z_0f3e3e48-9211-4084-97ef-768b44153def',
        profileImageHref: '',
        logoImage: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:23:58.465Z_bc9ad3e0-8cda-4138-8464-8150ce17de68',
        selectedTemplate: 'template1',
        logoImageHref: '',
        additionalFields: [],
        selectedIcons: ['facebook', 'instagram', 'linkedin'],
        socialLinks: {
          facebook: 'https://www.facebook.com',
          instagram: 'https://www.instagram.com',
          linkedin: 'https://www.linkedin.com',
        },
        customIcons: [],
        isWhiteIcons: false,
        iconLinksBlack: {
          behance: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:26:24.377Z_18428c82-ebcb-4a63-9f0f-6beb2ee11026',
          coffee: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:26:44.908Z_55f58ebd-5bdd-44f3-9f18-0fd518a4665f',
          diaspora: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:27:10.648Z_3f65a699-3c98-4f6a-9f2f-9da1f40fd0f7',
          dribbble: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:27:31.862Z_82835eef-437c-4000-995b-b32a02c5eb9a',
          facebook: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:27:59.871Z_1e1e577b-79c3-405b-93e1-4676857c4b9a',
          github: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:28:13.756Z_8b87990c-c0f1-4964-a151-a23e84ce2095',
          gitlab: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:28:34.932Z_8ac01c5d-61e7-490b-b273-25773e4dd25f',
          gitter: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:28:47.947Z_ead866ff-fa7c-4a14-a629-77d6c8c6cd70',
          heart: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:28:59.427Z_a87699b4-054c-46f0-b41d-ffa3a8a4a3fe',
          instagram: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:29:15.830Z_fd078727-f6fe-414e-8226-28ae4cabd534',
          linkedin: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:29:28.491Z_84cc5ce2-d45a-4995-ac2b-239bc40b72b5',
          mastodon: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:29:43.677Z_ad5e7c92-866e-4f43-a897-57984e948e74',
          menu: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:29:59.613Z_1f61506f-3b54-45b1-b74f-54be2b4aa27a',
          ok: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:30:13.068Z_3d9d81dd-e78a-4e4e-886d-3be4d19093eb',
          reddit: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:30:27.268Z_a0d90373-f846-494f-881d-c6a9750eb8ee',
          skype: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:30:50.676Z_eb8a6fc1-ad4f-4d1a-af17-e9eeae0dd4e8',
          slack: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:31:06.146Z_405327e7-aa1f-4a71-8001-deaea5af08d8',
          stackoverflow: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:31:23.538Z_7bc449b2-08de-4fac-bbc0-37e9797977da',
          telegram: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:31:37.960Z_759375eb-50b8-472e-aa92-f0b1cfd05a8b',
          twitter: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:31:54.611Z_fd67cf43-78cf-4bdc-8e59-eb655d07bdd7',
          vk: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:32:09.724Z_a702319f-633e-452d-8f58-8b81a38a7838',
          whatsapp: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:32:25.512Z_62b025d8-9080-4cf8-b167-2aeae6f90255',
          xing: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:32:42.328Z_4904923c-b82a-4ed2-be58-c55994aadaa9',
          youtube: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:32:54.794Z_9889edae-4a1d-4d77-b039-ed78e756ce99',
        },
        iconLinksWhite: {
          behance: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:35:04.528Z_89f82a62-b75f-4a10-bf64-1dd104e7971f',
          coffee: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:35:21.783Z_f43f83f2-e276-489d-8303-8a8d0cc50952',
          diaspora: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:35:36.238Z_bd6531f3-b3bd-4f58-810d-4e471cd96825',
          dribbble: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:35:54.183Z_2c4ebb8e-c6f7-4709-906b-7d8206ee8145',
          facebook: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:36:07.326Z_733c7249-76cf-47cd-81d3-dc01fd7c16e2',
          github: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:36:19.479Z_4bb18b73-1e2e-42b4-bf41-deca918720b2',
          gitlab: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:36:43.815Z_7f8619a8-dc0a-48e2-9f21-45485b0b9208',
          gitter: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:36:58.102Z_a50622d2-1935-4b1e-9f93-b602d6c43e97',
          heart: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:37:12.618Z_2b59cbe8-8282-4dd8-b093-8bbd02e8b5dc',
          instagram: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:37:34.722Z_44df56f6-3301-4c62-bcd6-df220d761715',
          linkedin: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:37:51.133Z_d9298197-8198-4847-919b-24e5c169aa98',
          mastodon: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:38:19.128Z_3194a01d-1934-40a9-8358-a14fea059373',
          menu: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:38:34.502Z_cdb2cc08-8b5d-4091-894d-3c3110d74182',
          ok: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:38:47.805Z_3ac145d3-eea4-4d63-ace4-d227bea6b652',
          reddit: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:39:02.420Z_2e146e92-4698-4c5c-a22b-e9fb8c24d9f7',
          skype: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:39:18.012Z_3e4ff1d6-5816-4731-a078-b35734845dd6',
          slack: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:39:34.053Z_50381480-4ffe-4856-bcab-f7fc4ed1f558',
          stackoverflow: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:39:54.077Z_9ac562de-4043-4285-940b-9c1b987c3184',
          telegram: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:40:13.045Z_e66c97a0-c527-48c5-9906-c794e54a880b',
          twitter: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:40:50.852Z_5911f23e-540c-41e5-bd8c-a285805e1b60',
          vk: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:41:07.102Z_47c8dd04-8004-4e4c-b2a9-0f0fc360f6b0',
          whatsapp: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:41:22.167Z_057d568f-0879-4e49-994d-48295440e8fa',
          xing: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:41:37.633Z_944dc88c-4d5e-4b63-a700-8ff42192d4db',
          youtube: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:41:51.250Z_68f28ef9-abab-4ce7-b2c4-b6d4c6e02b84',
        }
      };
      this.isDarkMode = false;
      this.navOpen = false;
      location.reload();
    }
  },
  watch: {
    '$route'(to) {
      this.checkIfPreview();
      this.checkIfDev();
      this.checkIfSettings();

      const hideHeaderPaths = [
        '/basic-info',
        '/signature-options',
        '/social-media',
        '/signature-templates',
      ];
      this.showHeader = !hideHeaderPaths.includes(to.path);
    },
    isDarkMode(newVal) {
      if (newVal) {
        document.body.classList.add('dark-mode');
      } else {
        document.body.classList.remove('dark-mode');
      }
    },
  },
  created() {
    this.entryTime = Date.now();

    this.updateCurrentDate();

    window.addEventListener('resize', this.handleResize);

    /*
    window.addEventListener('beforeunload', () => {
      this.trackUserSession('Exit');
    });

    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'hidden') {
        this.trackUserSession('Change');
      }
    });
    */

    this.checkIfPreview();
    this.checkIfDev();
    this.checkIfSettings();

    const id = this.$route.params.id;
    if (id) {
      this.loadSettingsFromId(id);
    }

    /*
    window.addEventListener('click', this.trackUserInteraction);
    window.addEventListener('keypress', this.trackUserInteraction);
    */
  },
  mounted() {
    document.addEventListener('click', this.registerClick);
    document.addEventListener('input', this.registerChange);
  },
  unmounted() {
    document.removeEventListener('click', this.registerClick);
    document.removeEventListener('input', this.registerChange);
    window.removeEventListener('resize', this.handleResize);
    /*
    window.removeEventListener('click', this.trackUserInteraction);
    window.removeEventListener('keypress', this.trackUserInteraction);

    window.removeEventListener('beforeunload', this.trackUserSession);

    document.removeEventListener('visibilitychange', this.trackUserSession);
    */
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css');

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  background: #f4f4f9;
  color: #333;
  transition: color 0.5s;
  height: 100%;
}

.dark-mode body {
  background: #181818;
}

.content .loader-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}

.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  background: linear-gradient(135deg, #ece9e6, #ffffff);
}

.dark-mode {
  background: #181818;
  color: #ffffff;
}

.content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.editor, .preview {
  padding: 1rem;
  background: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-bottom: 1rem;
}

.editor{
  margin: 0.5rem 0 0.5rem 0.5rem;
  padding-top: 0;
  overflow-x: hidden; 
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: #c0c0c0 #f0f0f0;
}

.preview.isPreview .reset-settings{
  display: none;
}

.preview{
  margin: 0.5rem 0.5rem 0.5rem 0;
}

.dark-mode .editor, .dark-mode .preview {
  background: #2c2c2c;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.top-nav {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  padding: 1rem 0;
  position: sticky;
  top: 0;
  background: inherit;
  z-index: 10;
  width: 100%;
}

.nav-tab {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.75rem 1.25rem;
  background: #e0e0e0;
  color: #333;
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
  transition: all 0.3s;
  cursor: pointer;
  flex: 1;
  min-width: 120px;
}

.nav-tab:hover {
  background: #d0d0d0;
  transform: scale(1.02);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.nav-tab.code-button {
  background: #FF6A00;
  color: #ffffff;
}

.nav-tab.code-button:hover {
  background: #FF6A00;
}

.nav-tab.toggle-dark-mode {
  background: #28c840;
  color: #ffffff;
}

.nav-tab.toggle-dark-mode:hover {
  background: #24b43a;
}

.dark-mode .nav-tab {
  background: #3c3c3c;
  color: #ddd;
}

.dark-mode .nav-tab:hover {
  background: #4c4c4c;
}

.dark-mode .nav-tab.code-button {
  background: #c15000;
}

.dark-mode .nav-tab.code-button:hover {
  background: #c15000;
}

.dark-mode .nav-tab.toggle-dark-mode {
  background: #24b43a;
}

.dark-mode .nav-tab.toggle-dark-mode:hover {
  background: #209f33;
}

.header {
  margin-bottom: 1rem;
}

h2 {
  font-size: 1.5rem;
  margin: 0;
  color: #333;
  transition: color 0.5s;
}

p {
  font-size: 1rem;
  margin: 0;
  color: #666;
  transition: color 0.5s;
}

a {
  color: #FF6A00;
  text-decoration: none;
  transition: color 0.3s;
}

a:hover {
  color: #FF6A00;
}

.dark-mode h2, .dark-mode p, .dark-mode a {
  color: #ffffff;
}

.dark-mode a:hover {
  color: #c15000;
}

.email-client {
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  height: 100%;
}

.dark-mode .email-client {
  border: 1px solid #555;
}

.title-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  background: #e0e0e0;
  border-bottom: 1px solid #ddd;
}

.dark-mode .title-bar {
  background: #3c3c3c;
  border-bottom: 1px solid #555;
}

.buttons {
  display: flex;
  gap: 0.5rem;
}

.close, .minimize, .maximize {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
}

.close {
  background-color: #ff5f56;
}

.minimize {
  background-color: #ffbd2e;
}

.maximize {
  background-color: #28c840;
}

.actions {
  display: flex;
  align-items: center;
}

.actions .dark-mode-toggle,
.actions .reset-settings {
  cursor: pointer;
  margin-left: 10px;
}

.fa-moon, .fa-sun, .fa-undo-alt{
  transition: transform 0.4s;
}

.fa-moon:hover, .fa-sun:hover, .fa-undo-alt:hover{
  transform: scale(1.2);
}

.close:hover, .minimize:hover, .maximize:hover {
  transform: scale(1.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.dark-mode .close {
  background-color: #ff5f56;
}

.dark-mode .minimize {
  background-color: #ffbd2e;
}

.dark-mode .maximize {
  background-color: #28c840;
}

.title {
  font-weight: bold;
  color: #333;
}

.dark-mode .title {
  color: #fff;
}

.toolbar {
  display: flex;
  gap: 1rem;
  padding: 0.5rem 1rem;
  background: #f0f0f0;
  border-bottom: 1px solid #ddd;
}

.dark-mode .toolbar {
  background: #3c3c3c;
  border-bottom: 1px solid #555;
}

.toolbar i {
  cursor: pointer;
  color: #555;
  transition: color 0.3s, transform 0.3s;
}

.toolbar i:hover {
  color: #000;
  transform: scale(1.2);
}

.dark-mode .toolbar i {
  color: #ddd;
}

.dark-mode .toolbar i:hover {
  color: #fff;
}

.email-header {
  padding: 1rem;
  border-bottom: 1px solid #ddd;
  background: #fff;
}

.dark-mode .email-header {
  background: #3c3c3c;
  border-bottom: 1px solid #555;
}

.email-from, .email-to, .email-subject, .email-date {
  margin-bottom: 0.5rem;
  color: #555;
}

.dark-mode .email-from, .dark-mode .email-to, .dark-mode .email-subject, .dark-mode .email-date {
  color: #ddd;
}

.email-body {
  padding: 1rem;
  background: #fefefe;
  flex: 1;
  overflow-y: auto;
}

.dark-mode .email-body {
  background: #4c4c4c;
}

.editor::-webkit-scrollbar, .preview::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

.editor::-webkit-scrollbar-track, .preview::-webkit-scrollbar-track {
  background: #f0f0f0;
  border-radius: 6px;
}

.editor::-webkit-scrollbar-thumb, .preview::-webkit-scrollbar-thumb {
  background-color: #c0c0c0;
  border-radius: 6px;
  border: 3px solid #f0f0f0;
}

.editor::-webkit-scrollbar-thumb:hover, .preview::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a0;
}

.dark-mode .editor, .dark-mode .preview {
  scrollbar-color: #6b6b6b #2c2c2c;
}

.dark-mode .editor::-webkit-scrollbar-track, .dark-mode .preview::-webkit-scrollbar-track {
  background: #2c2c2c;
}

.dark-mode .editor::-webkit-scrollbar-thumb, .dark-mode .preview::-webkit-scrollbar-thumb {
  background-color: #6b6b6b;
  border: 3px solid #2c2c2c;
}

.dark-mode .editor::-webkit-scrollbar-thumb:hover, .dark-mode .preview::-webkit-scrollbar-thumb:hover {
  background-color: #4c4c4c;
}

.hamburger {
  display: none;
  font-size: 1.5rem;
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  position: absolute;
  top: 1rem;
  left: 1rem;
  padding: 0.2rem 0.5rem;
  background-color: #ffffff;
  border: 1px solid #4c4c4c;
  border-radius: 5px;
  z-index: 100;
}

.dark-mode .hamburger {
  background-color: #2c2c2c;
}

@media (min-width: 997px) {
  .logo{
    display: none;
  }

  .content {
    flex-direction: row;
  }

  .editor {
    flex: 1;
    max-width: 30%;
  }

  .preview {
    flex: 2;
    margin-left: 1rem;
  }

  .nav-tab {
    padding: 0.75rem 1.5rem;
  }
}

@media (max-width: 480px) {
  .nav-tab {
    flex: 1 1 100%;
  }

  .header h2 {
    font-size: 1rem;
  }

  .header p {
    font-size: 0.75rem;
  }
}

@media (max-width: 997px) {
    @keyframes slideIn {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }

  @keyframes slideOut {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }

  .logo{
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    height: 45px;
  }

  .app{
    height: unset;
  }
  
  .content {
    flex-direction: column;
    overflow-y: auto;
  }

  .editor {
    order: 2;
    margin: 0.5rem 0 1rem 0;
    padding-top: 1rem;
    overflow: hidden;
  }

  .preview {
    order: 1;
    margin: 4rem 0 0.5rem 0;
  }

  .preview.isPreview {
    margin: 0.5rem;
    margin-top: 4rem;
    height: calc(100vh - 7rem);
  }

  .editor .preview{
    height: auto;
    flex: 1 0 auto;
    overflow-y: hidden;
  }

  .title-bar, .toolbar, .email-header, .email-body {
    padding: 0.5rem;
  }

  .header h2 {
    font-size: 1.25rem;
  }

  .header p {
    font-size: 0.875rem;
  }

  .hamburger {
    display: block;
  }

  .top-nav:not(.hidden) {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start
  }

  .top-nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 60%;
    background: #ffffff;
    z-index: 1000;
    flex-wrap: nowrap;
    padding: 1rem;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  }

  .dark-mode .top-nav {
    background: #6b6b6b;
  }

  .top-nav a, .top-nav button{
    padding: 2rem 0;
    margin: 0.5rem 0;
  }

  .top-nav {
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
  }

  .top-nav.hidden {
    transform: translateX(-100%);
    opacity: 0;
  }

  .top-nav.visible {
    transform: translateX(0);
    opacity: 1;
  }

  .top-nav.hidden a, .top-nav.hidden button{
    display: none;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 10;
    transition: opacity 0.3s;
  }

  .hamburger {
    position: fixed;
    top: 1rem;
    left: 1rem;
    z-index: 1100;
  }
}
</style>
