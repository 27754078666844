<template>

  <!-- Password Modal -->
  <div v-if="showPasswordModal" class="modal">
    <div class="modal-content password-modal">
      <h3>Enter Admin Password</h3>
      <input
        type="password"
        v-model="password"
        placeholder="Enter password"
        class="password-input"
        @keyup.enter="checkPassword"
      />
      <div class="modal-buttons">
        <button @click="checkPassword">Submit</button>
      </div>
      <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
    </div>
  </div>

<div v-if="!showPasswordModal" class="admin-panel">
  <h1 class="page-title">User Tracking Dashboard</h1>

  <!-- Charts -->
  <div class="charts-grid">
    <div class="chart-card">
      <div class="loader-wrapper" v-if="loading">
        <LoaderApp :isLoading="loading" />
      </div>
      <div v-else>
        <div class="chart-card-header">
          <i class="fas fa-clock"></i>
          <h3>Time Spent on Site</h3>
        </div>
        <canvas ref="timeSpentChart"></canvas>
      </div>
    </div>

    <div class="chart-card">
      <div class="loader-wrapper" v-if="loading">
        <LoaderApp :isLoading="loading" />
      </div>
      <div v-else>
        <div class="chart-card-header">
          <i class="fas fa-mouse-pointer"></i>
          <h3>Click Count</h3>
        </div>
        <canvas ref="clickCountChart"></canvas>
      </div>
    </div>

    <div class="chart-card">
      <div class="loader-wrapper" v-if="loading">
        <LoaderApp :isLoading="loading" />
      </div>
      <div v-else>
        <div class="chart-card-header">
          <i class="fas fa-edit"></i>
          <h3>Change Count</h3>
        </div>
        <canvas ref="changeCountChart"></canvas>
      </div>
    </div>

    <div class="chart-card">
      <div class="loader-wrapper" v-if="loading">
        <LoaderApp :isLoading="loading" />
      </div>
      <div v-else>
        <div class="chart-card-header">
          <i class="fas fa-map-marker-alt"></i>
          <h3>IP Addresses</h3>
        </div>
        <canvas ref="ipAddressChart"></canvas>
      </div>
    </div>

    <div class="chart-card">
      <div class="loader-wrapper" v-if="loading">
        <LoaderApp :isLoading="loading" />
      </div>
      <div v-else>
        <div class="chart-card-header">
          <i class="fas fa-desktop"></i>
          <h3>Device Type</h3>
        </div>
        <canvas ref="deviceTypeChart"></canvas>
      </div>
    </div>

    <div class="chart-card">
      <div class="loader-wrapper" v-if="loading">
        <LoaderApp :isLoading="loading" />
      </div>
      <div v-else>
        <div class="chart-card-header">
          <i class="fas fa-expand-arrows-alt"></i>
          <h3>Screen Resolution</h3>
        </div>
        <canvas ref="screenResolutionChart"></canvas>
      </div>
    </div>

    <div class="chart-card">
      <div class="loader-wrapper" v-if="loading">
        <LoaderApp :isLoading="loading" />
      </div>
      <div v-else>
        <div class="chart-card-header">
          <i class="fas fa-language"></i>
          <h3>Browser Language</h3>
        </div>
        <canvas ref="browserLanguageChart"></canvas>
      </div>
    </div>

    <div class="chart-card">
      <div class="loader-wrapper" v-if="loading">
        <LoaderApp :isLoading="loading" />
      </div>
      <div v-else>
        <div class="chart-card-header">
          <i class="fas fa-info-circle"></i>
          <h3>Browser Info</h3>
        </div>
        <canvas ref="browserInfoChart"></canvas>
      </div>
    </div>
  </div>

  <!-- User Tracking Data Table -->
  <h2 class="section-title">User Tracking Data</h2>
  <div class="user-tracking-container">
    <div class="loader-wrapper" v-if="loading">
      <LoaderApp :isLoading="loading" />
    </div>
    <div v-else>
      <input
        type="text"
        v-model="userSearchQuery"
        placeholder="Search user tracking data..."
        class="search-input"
      />
      <table class="styled-table">
        <thead>
          <tr>
            <th @click="sortUserTable('id')">
              ID <i class="fas fa-sort"></i>
            </th>
            <th @click="sortUserTable('entry_time')">
              Entry Time <i class="fas fa-sort"></i>
            </th>
            <th @click="sortUserTable('exit_time')">
              Exit Time <i class="fas fa-sort"></i>
            </th>
            <th @click="sortUserTable('time_spent')">
              Time Spent (s) <i class="fas fa-sort"></i>
            </th>
            <th @click="sortUserTable('click_count')">
              Click Count <i class="fas fa-sort"></i>
            </th>
            <th @click="sortUserTable('change_count')">
              Change Count <i class="fas fa-sort"></i>
            </th>
            <th @click="sortUserTable('screen_resolution')">
              Screen Resolution <i class="fas fa-sort"></i>
            </th>
            <th @click="sortUserTable('device_type')">
              Device Type <i class="fas fa-sort"></i>
            </th>
            <th @click="sortUserTable('browser_language')">
              Browser Language <i class="fas fa-sort"></i>
            </th>
            <th @click="sortUserTable('ip_address')">
              IP Address <i class="fas fa-sort"></i>
            </th>
            <th @click="sortUserTable('browser_info')">
              Browser Info <i class="fas fa-sort"></i>
            </th>
            <th @click="sortUserTable('event_type')">
              Event Type <i class="fas fa-sort"></i>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(user, index) in paginatedUsers" :key="index">
            <td>{{ user.id }}</td>
            <td>{{ user.entry_time }}</td>
            <td>{{ user.exit_time }}</td>
            <td>{{ user.time_spent }}</td>
            <td>{{ user.click_count }}</td>
            <td>{{ user.change_count }}</td>
            <td>{{ user.screen_resolution }}</td>
            <td>{{ user.device_type }}</td>
            <td>{{ user.browser_language }}</td>
            <td>{{ user.ip_address }}</td>
            <td>{{ user.browser_info }}</td>
            <td>{{ user.event_type }}</td>
          </tr>
        </tbody>
      </table>
      <div class="pagination">
        <button @click="prevUserPage" :disabled="currentUserPage === 1">Prev</button>
        <span>Page {{ currentUserPage }} of {{ totalUserPages }}</span>
        <button @click="nextUserPage" :disabled="currentUserPage === totalUserPages">Next</button>
      </div>
    </div>
  </div>

  <!-- Email Signature Data Table -->
  <h2 class="section-title">Email Signature Data</h2>
  <div class="email-data-container">
    <div class="loader-wrapper" v-if="loading">
      <LoaderApp :isLoading="loading" />
    </div>
    <div v-else>
      <input
        type="text"
        v-model="emailSearchQuery"
        placeholder="Search email data..."
        class="search-input"
      />
      <table class="styled-table">
        <thead>
          <tr>
            <th @click="sortTable('email')">Email <i class="fas fa-sort"></i></th>
            <th @click="sortTable('created_at')">Date Generated <i class="fas fa-sort"></i></th>
            <th>Edit Link</th>
            <th>Preview Link</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(email, index) in paginatedEmails" :key="index">
            <td>{{ email.email }}</td>
            <td>{{ email.created_at }}</td>
            <td>
              <a :href="generateEditLink(email.id)" target="_blank">Edit Signature</a>
            </td>
            <td>
              <a :href="generatePreviewLink(email.id)" target="_blank">Preview Signature</a>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="pagination">
        <button @click="prevPage" :disabled="currentPage === 1">Prev</button>
        <span>Page {{ currentPage }} of {{ totalPages }}</span>
        <button @click="nextPage" :disabled="currentPage === totalPages">Next</button>
      </div>
    </div>
  </div>

  <!-- Database Management Section -->
  <h2 class="section-title">Database Management</h2>
  <div class="db-management-container">
    <div class="db-card">
      <div class="db-card-icon">
        <i class="fas fa-download"></i>
      </div>
      <div class="db-card-content">
        <h3>Download Backup</h3>
        <p>Download the latest backup of the database.</p>
        <button @click="backupDatabase">Download</button>
      </div>
    </div>

    <div class="db-card">
      <div class="db-card-icon">
        <i class="fas fa-upload"></i>
      </div>
      <div class="db-card-content">
        <h3>Upload Database</h3>
        <p>Upload an SQL file to restore the database.</p>
        <button @click="triggerFileUpload">Upload</button>
        <input type="file" ref="fileInput" @change="uploadDatabase($event)" accept=".gz" style="display: none;" />
      </div>
    </div>

    <div class="db-card">
      <div class="db-card-icon">
        <i class="fas fa-redo-alt"></i>
      </div>
      <div class="db-card-content">
        <h3>Reset Database</h3>
        <p>Reset all data in the current database.</p>
        <button @click="confirmResetDatabase">Reset</button>
      </div>
    </div>
  </div>

  <!-- Modal for Confirming Database Reset -->
  <div v-if="isConfirmingReset" class="modal-overlay" @click.self="closeResetModal">
    <div class="modal-content">
      <h3>Confirm Reset</h3>
      <p>Are you sure you want to reset the database? This action cannot be undone.</p>
      <div class="modal-buttons">
        <button @click="resetDatabase" class="confirm-button">Yes, Reset</button>
        <button @click="closeResetModal" class="cancel-button">Cancel</button>
      </div>
    </div>
  </div>

  <!-- Toast Message -->
  <div v-if="toastMessage" class="toast">{{ toastMessage }}</div>

</div>
</template>

<script>
import { ref, computed, onMounted, watch } from "vue";
import { Chart, registerables } from "chart.js";
import LoaderApp from "../components/LoaderApp.vue";
import { BASE_URL } from '../config';

Chart.register(...registerables);

export default {
  name: "TrackingDashboard",
  components: {
    LoaderApp,
  },
  setup() {
    const timeSpentChart = ref(null);
    const clickCountChart = ref(null);
    const changeCountChart = ref(null);
    const deviceTypeChart = ref(null);
    const screenResolutionChart = ref(null);
    const browserLanguageChart = ref(null);
    const ipAddressChart = ref(null);
    const browserInfoChart = ref(null);
    const userTrackingData = ref([]);
    const emailData = ref([]);
    const fileInput = ref(null);
    const toastMessage = ref("");
    const isConfirmingReset = ref(false);
    const loading = ref(true);
    const showPasswordModal = ref(true);
    const password = ref("");
    const errorMessage = ref("");
    const emailSearchQuery = ref("");
    const userSearchQuery = ref("");
    const currentPage = ref(1);
    const currentUserPage = ref(1);
    const itemsPerPage = ref(10);

    const ADMIN_PASSWORD = "Admin123";

    const formatDateTimeForDisplay = (isoDate) => {
      if (!isoDate) return "Invalid Date";
      const date = new Date(isoDate);
      if (isNaN(date.getTime())) return "Invalid Date";

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

    const formatDateTimeForFilename = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");
      return `${year}_${month}_${day}_${hours}_${minutes}_${seconds}`;
    };

    const checkPassword = () => {
      if (password.value === ADMIN_PASSWORD) {
        localStorage.setItem("isAuthenticated", "true");
        showPasswordModal.value = false;
        fetchUserTrackingData();
        fetchDataAndRenderCharts();
        fetchEmailData();
      } else {
        errorMessage.value = "Incorrect password. Please try again.";
      }
    };

    const filteredEmails = computed(() => {
      return emailData.value.filter((email) =>
        email.email.toLowerCase().includes(emailSearchQuery.value.toLowerCase())
      );
    });

    const filteredUsers = computed(() => {
      return userTrackingData.value.filter((user) =>
        Object.values(user)
          .join(" ")
          .toLowerCase()
          .includes(userSearchQuery.value.toLowerCase())
      );
    });

    const sortedEmails = computed(() => {
      const sorted = [...filteredEmails.value];
      if (sortBy.value === "email") {
        sorted.sort((a, b) => a.email.localeCompare(b.email));
      } else if (sortBy.value === "created_at") {
        sorted.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
      }
      return sorted;
    });

    const sortedUsers = computed(() => {
      const sorted = [...filteredUsers.value];
      if (userSortBy.value === "id") {
        sorted.sort((a, b) => a.id - b.id);
      } else if (userSortBy.value === "entry_time") {
        sorted.sort((a, b) => new Date(a.entry_time) - new Date(b.entry_time));
      } else if (userSortBy.value === "exit_time") {
        sorted.sort((a, b) => new Date(a.exit_time) - new Date(b.exit_time));
      } else if (userSortBy.value === "time_spent") {
        sorted.sort((a, b) => a.time_spent - b.time_spent);
      } else if (userSortBy.value === "click_count") {
        sorted.sort((a, b) => a.click_count - b.click_count);
      } else if (userSortBy.value === "change_count") {
        sorted.sort((a, b) => a.change_count - b.change_count);
      } else if (userSortBy.value === "screen_resolution") {
        sorted.sort((a, b) => a.screen_resolution.localeCompare(b.screen_resolution));
      } else if (userSortBy.value === "device_type") {
        sorted.sort((a, b) => a.device_type.localeCompare(b.device_type));
      } else if (userSortBy.value === "browser_language") {
        sorted.sort((a, b) => a.browser_language.localeCompare(b.browser_language));
      } else if (userSortBy.value === "ip_address") {
        sorted.sort((a, b) => a.ip_address.localeCompare(b.ip_address));
      } else if (userSortBy.value === "browser_info") {
        sorted.sort((a, b) => a.browser_info.localeCompare(b.browser_info));
      } else if (userSortBy.value === "event_type") {
        sorted.sort((a, b) => a.event_type.localeCompare(b.event_type));
      }
      return sorted;
    });

    const totalPages = computed(() => {
      return Math.ceil(sortedEmails.value.length / itemsPerPage.value);
    });

    const totalUserPages = computed(() => {
      return Math.ceil(sortedUsers.value.length / itemsPerPage.value);
    });

    const paginatedEmails = computed(() => {
      const start = (currentPage.value - 1) * itemsPerPage.value;
      const end = start + itemsPerPage.value;
      return sortedEmails.value.slice(start, end);
    });

    const paginatedUsers = computed(() => {
      const start = (currentUserPage.value - 1) * itemsPerPage.value;
      const end = start + itemsPerPage.value;
      return sortedUsers.value.slice(start, end).map((user) => {
        return {
          ...user,
          entry_time: formatDateTimeForDisplay(user.entry_time),
          exit_time: formatDateTimeForDisplay(user.exit_time),
        };
      });
    });

    const sortBy = ref("");
    const userSortBy = ref("");

    const sortTable = (column) => {
      if (sortBy.value === column) {
        sortBy.value = "";
      } else {
        sortBy.value = column;
      }
    };

    const sortUserTable = (column) => {
      if (userSortBy.value === column) {
        userSortBy.value = "";
      } else {
        userSortBy.value = column;
      }
    };

    const nextPage = () => {
      if (currentPage.value < totalPages.value) {
        currentPage.value++;
      }
    };

    const nextUserPage = () => {
      if (currentUserPage.value < totalUserPages.value) {
        currentUserPage.value++;
      }
    };

    const prevPage = () => {
      if (currentPage.value > 1) {
        currentPage.value--;
      }
    };

    const prevUserPage = () => {
      if (currentUserPage.value > 1) {
        currentUserPage.value--;
      }
    };

    const showToast = (message) => {
      toastMessage.value = message;
      setTimeout(() => {
        toastMessage.value = "";
      }, 3000);
    };

    const confirmResetDatabase = () => {
      isConfirmingReset.value = true;
    };

    const closeResetModal = () => {
      isConfirmingReset.value = false;
    };

    const fetchUserTrackingData = async () => {
      try {
        const response = await fetch(`${BASE_URL}get-user-tracking-data`);
        const allData = await response.json();
        userTrackingData.value = allData.map((user) => ({
          ...user,
          entry_time: formatDateTimeForDisplay(user.entry_time),
          exit_time: formatDateTimeForDisplay(user.exit_time),
        }));
      } catch (error) {
        console.error("Error fetching user tracking data:", error);
      } finally {
        loading.value = false;
      }
    };

    const fetchEmailData = async () => {
      try {
        const response = await fetch(`${BASE_URL}get-email-data`);
        const allEmails = await response.json();
        emailData.value = allEmails.map((email) => ({
          ...email,
          created_at: formatDateTimeForDisplay(email.created_at),
        }));
      } catch (error) {
        console.error("Error fetching email data:", error);
      } finally {
        loading.value = false;
      }
    };

    const generateEditLink = (id) => {
      const baseUrl = window.location.origin;
      let path = window.location.pathname;

      path = path.replace(/\/admin\/?$/, "/");
      path = path.endsWith("/") ? path : `${path}/`;

      return `${baseUrl}${path}settings/${id}`;
    };

    const generatePreviewLink = (id) => {
      const baseUrl = window.location.origin;
      let path = window.location.pathname;

      path = path.replace(/\/admin\/?$/, "/");
      path = path.endsWith("/") ? path : `${path}/`;

      return `${baseUrl}${path}preview/${id}`;
    };

    const fetchDataAndRenderCharts = async () => {
      try {
        const response = await fetch(`${BASE_URL}get-user-tracking-data`);
        const data = await response.json();

        const limitedData = data.slice(-30);
        const labels = limitedData.map((item) =>
          formatDateTimeForDisplay(item.entry_time)
        );
        const timeSpentData = limitedData.map((item) => item.time_spent);
        const clickCountData = limitedData.map((item) => item.click_count);
        const changeCountData = limitedData.map((item) => item.change_count);

        const deviceTypeData = aggregateData(data.map((item) => item.device_type));
        const screenResolutionData = aggregateData(
          data.map((item) => item.screen_resolution)
        );
        const browserLanguageData = aggregateData(
          data.map((item) => item.browser_language)
        );
        const ipAddressData = aggregateData(data.map((item) => item.ip_address));
        const browserInfoData = aggregateData(data.map((item) => item.browser_info));

        renderChart(
          timeSpentChart.value,
          "line",
          labels,
          timeSpentData,
          "Time Spent (seconds)"
        );
        renderChart(
          clickCountChart.value,
          "bar",
          labels,
          clickCountData,
          "Click Count"
        );
        renderChart(
          changeCountChart.value,
          "bar",
          labels,
          changeCountData,
          "Change Count"
        );
        renderChart(
          deviceTypeChart.value,
          "pie",
          Object.keys(deviceTypeData),
          Object.values(deviceTypeData),
          "Device Type"
        );
        renderChart(
          screenResolutionChart.value,
          "pie",
          Object.keys(screenResolutionData),
          Object.values(screenResolutionData),
          "Screen Resolution"
        );
        renderChart(
          browserLanguageChart.value,
          "pie",
          Object.keys(browserLanguageData),
          Object.values(browserLanguageData),
          "Browser Language"
        );
        renderChart(
          ipAddressChart.value,
          "bar",
          Object.keys(ipAddressData),
          Object.values(ipAddressData),
          "Visits by IP Address"
        );
        renderChart(
          browserInfoChart.value,
          "pie",
          Object.keys(browserInfoData),
          Object.values(browserInfoData),
          "Browser Info"
        );
      } catch (error) {
        console.error("Error fetching tracking data:", error);
      } finally {
        loading.value = false;
      }
    };

    const aggregateData = (dataArray) => {
      return dataArray.reduce((acc, item) => {
        acc[item] = (acc[item] || 0) + 1;
        return acc;
      }, {});
    };

    const renderChart = (canvasRef, type, labels, data, label) => {
      if (!canvasRef) {
        console.error(`Canvas reference is null for chart: ${label}`);
        return;
      }

      const ctx = canvasRef.getContext("2d");
      if (!ctx) {
        console.error(`Failed to get context for chart: ${label}`);
        return;
      }

      new Chart(ctx, {
        type: type,
        data: {
          labels: labels,
          datasets: [
            {
              label: label,
              data: data,
              backgroundColor:
                type === "pie"
                  ? generateRandomColors(data.length)
                  : "rgba(75, 192, 192, 0.2)",
              borderColor: "rgba(75, 192, 192, 1)",
              borderWidth: 1,
            },
          ],
        },
        options: {
          responsive: true,
          scales: type !== "pie" ? { y: { beginAtZero: true } } : {},
        },
      });
    };

    const generateRandomColors = (numColors) => {
      return Array.from({ length: numColors }, () =>
        `hsl(${Math.floor(Math.random() * 360)}, 100%, 75%)`
      );
    };

    const backupDatabase = async () => {
      try {
        const response = await fetch(`${BASE_URL}collection/backup`);
        if (!response.ok) {
          throw new Error("Failed to download backup.");
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `gsignature_backup_${formatDateTimeForFilename(new Date())}.gz`;
        a.click();
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error("Error downloading backup:", error);
        showToast("Error downloading backup");
      }
    };

    const triggerFileUpload = () => {
      if (fileInput.value) {
        fileInput.value.click();
      }
    };

    const uploadDatabase = async (event) => {
      const file = event.target.files[0];

      if (!file) {
        showToast("No file selected");
        return;
      }

      const formData = new FormData();
      formData.append("file", file);

      try {
        const response = await fetch(`${BASE_URL}collection/restore`, {
          method: "POST",
          body: formData,
        });

        if (response.ok) {
          showToast("Database restored successfully");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          showToast("Failed to restore the database");
        }
      } catch (error) {
        console.error("Error uploading database:", error);
        showToast("Error uploading database");
      }
    };

    const resetDatabase = async () => {
      try {
        const response = await fetch(`${BASE_URL}collection/reset`, {
          method: "POST",
        });

        if (response.ok) {
          showToast("Database reset successfully");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          showToast("Failed to reset the database");
        }
      } catch (error) {
        console.error("Error resetting database:", error);
        showToast("Error resetting database");
      } finally {
        isConfirmingReset.value = false;
      }
    };

    onMounted(() => {
      if (localStorage.getItem("isAuthenticated")) {
        showPasswordModal.value = false;
        fetchUserTrackingData();
        fetchDataAndRenderCharts();
        fetchEmailData();
      } else {
        showPasswordModal.value = true;
      }
    });

    onMounted(() => {
      const handleEscape = (event) => {
        if (event.key === "Escape" && isConfirmingReset.value) {
          closeResetModal();
        }
      };
      window.addEventListener("keydown", handleEscape);

      return () => {
        window.removeEventListener("keydown", handleEscape);
      };
    });

    watch([userSearchQuery, userSortBy, currentUserPage], () => {
      paginatedUsers.value = filteredUsers.value.slice(
        (currentUserPage.value - 1) * itemsPerPage.value,
        currentUserPage.value * itemsPerPage.value
      );
    });

    return {
      timeSpentChart,
      clickCountChart,
      changeCountChart,
      deviceTypeChart,
      screenResolutionChart,
      browserLanguageChart,
      ipAddressChart,
      browserInfoChart,
      userTrackingData,
      emailData,
      generateEditLink,
      generatePreviewLink,
      backupDatabase,
      uploadDatabase,
      resetDatabase,
      confirmResetDatabase,
      closeResetModal,
      triggerFileUpload,
      fileInput,
      toastMessage,
      isConfirmingReset,
      loading,
      emailSearchQuery,
      userSearchQuery,
      currentPage,
      currentUserPage,
      itemsPerPage,
      paginatedEmails,
      paginatedUsers,
      totalPages,
      totalUserPages,
      sortTable,
      sortUserTable,
      nextPage,
      nextUserPage,
      prevPage,
      prevUserPage,
      sortBy,
      userSortBy,
      showPasswordModal,
      password,
      checkPassword,
      errorMessage,
    };
  },
};
</script>

<style scoped>
.admin-panel {
  padding: 20px;
  max-width: 1300px;
  margin: 0 auto;
}

.page-title {
  font-size: 2em;
  text-align: center;
  margin-bottom: 30px;
}

.charts-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  margin-bottom: 30px;
}

.chart-card {
  background-color: #f5f5f5;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  position: relative;
}

.chart-card-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.chart-card-header i {
  font-size: 1.5em;
  color: #009879;
  margin-right: 10px;
}

.chart-card h3 {
  font-size: 1.3em;
  color: #333;
}

.loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.section-title {
  font-size: 1.8em;
  margin-top: 40px;
  text-align: center;
  color: #333;
}

.user-tracking-container,
.email-data-container {
  margin: 20px 0;
  overflow-x: auto;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.05);
  padding: 20px;
}

.search-input {
  margin-bottom: 15px;
  padding: 10px;
  width: 100%;
  max-width: 400px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 1em;
  transition: border-color 0.3s ease;
}

.search-input:focus {
  border-color: #009879;
  outline: none;
}

.styled-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.9em;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
}

.styled-table thead tr {
  background-color: #009879;
  color: #ffffff;
  text-align: left;
}

.styled-table th,
.styled-table td {
  padding: 12px 15px;
  text-align: center;
  position: relative;
  border-bottom: 1px solid #dddddd;
}

.styled-table th {
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.styled-table th:hover {
  background-color: #007a63;
}

.styled-table th i {
  margin-left: 5px;
  font-size: 0.8em;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}

.styled-table tbody tr:hover {
  background-color: #f1f1f1;
}

.styled-table tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}

a {
  color: #0071e3;
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  color: #005bb5;
  text-decoration: underline;
}

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.pagination button {
  padding: 8px 16px;
  border: 1px solid #ccc;
  border-radius: 6px;
  background-color: #009879;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  font-size: 1em;
}

.pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination button:hover:not(:disabled) {
  background-color: #007a63;
}

.pagination span {
  font-size: 1em;
  font-weight: 600;
}

.db-management-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 30px;
}

@media (min-width: 768px) {
  .db-management-container {
    flex-direction: row;
    justify-content: space-around;
  }
}

.db-card {
  background-color: #f9f9f9;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  flex: 1;
}

.db-card-icon {
  font-size: 2.5em;
  color: #009879;
  margin-bottom: 10px;
}

.db-card-content h3 {
  font-size: 1.3em;
  margin-bottom: 10px;
  color: #333;
}

.db-card-content p {
  font-size: 0.9em;
  color: #666;
  margin-bottom: 15px;
}

.db-card-content button {
  background-color: #009879;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.db-card-content button:hover {
  background-color: #007a63;
}

.db-card-content input[type="file"] {
  display: none;
}

.toast {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #009879;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  font-size: 1em;
  z-index: 1000;
  opacity: 0.9;
  transition: opacity 0.3s ease;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
  animation: fadeIn 0.3s ease;
}

.modal-content {
  background: #fff;
  padding: 30px;
  border-radius: 12px;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 400px;
  animation: slideDown 0.5s ease;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideDown {
  from { transform: translateY(-50px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

.modal-content h3 {
  font-size: 1.5em;
  margin-bottom: 15px;
  color: #333;
}

.modal-content p {
  font-size: 1em;
  color: #666;
  margin-bottom: 20px;
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
  gap: 3em;
}

.modal-buttons button {
  background-color: #009879;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;
  margin-top: 20px;
}

.modal-buttons button:hover {
  background-color: #007a63;
}

.password-modal .password-input {
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 1em;
  box-sizing: border-box;
}

.error-message {
  color: red;
  margin-top: 10px;
  font-size: 0.9em;
}
</style>
