<template>
  <div>
    <div class="header">
      <h2>Basic Information</h2>
    </div>

    <!-- Profile Image Upload -->
    <div class="form-group">
      <label for="profileImage">Profile Image:</label>
      <div class="image-upload">
        <div class="image-preview" v-if="profileImageView">
          <img :src="profileImageView" alt="Profile Image">
          <button @click="removeImage" class="btn-remove">✖</button>
        </div>
        
        <input type="file" id="profileImage" @change="handleImageUpload" class="input-file">
        <!-- <label for="profileImage" class="file-label">
          <div class="file-label-content">
            <span class="file-icon"><i class="fas fa-cloud-upload-alt"></i></span>
          </div>
        </label> -->
        <input type="text" id="profileImageURL" v-model="profileImageURL" @input="updateProfileImageURL" placeholder="Enter image URL">
      </div>
      <div class="form-group">
        <label for="profileImageHref">Profile Image href (optional):</label>
        <input type="text" id="profileImageHref" v-model="profileImageHref" @input="updateProfileImageHref" placeholder="Enter href URL">
      </div>
    </div>

    <!-- Logo Image Upload -->
    <div class="form-group">
      <label for="logoImage">Logo:</label>
      <div class="image-upload">
        <div class="image-preview" v-if="localLogoImage">
          <img :src="localLogoImage" alt="Logo">
          <button @click="removeLogo" class="btn-remove">✖</button>
        </div>
        
        <input type="file" id="logoImage" @change="handleLogoUpload" class="input-file">
        <!-- <label for="logoImage" class="file-label">
          <div class="file-label-content">
            <span class="file-icon"><i class="fas fa-cloud-upload-alt"></i></span>
          </div>
        </label> -->
        <input type="text" id="logoImageURL" v-model="logoImageURL" @input="updateLogoImageURL" placeholder="Enter image URL">
      </div>
      <div class="form-group">
        <label for="logoImageHref">Logo href (optional):</label>
        <input type="text" id="logoImageHref" v-model="logoImageHref" @input="updateLogoImageHref" placeholder="Enter href URL">
      </div>
    </div>

    <!-- Form -->
    <form @submit.prevent>
      <!-- Standard Fields -->
      <div class="form-group">
        <label for="name">Name:</label>
        <input type="text" id="name" v-model="localName" @input="updateField('name', localName)" placeholder="Enter name">
      </div>
      <div class="form-group">
        <label for="jobTitle">Job Title:</label>
        <input type="text" id="jobTitle" v-model="localJobTitle" @input="updateField('jobTitle', localJobTitle)" placeholder="Enter job title">
      </div>
      <div class="form-group">
        <label for="phoneNumber">Phone Number:</label>
        <input type="text" id="phoneNumber" v-model="localPhoneNumber" @input="updateField('phoneNumber', localPhoneNumber)" placeholder="Enter phone number">
      </div>
      <div class="form-group">
        <label for="emailAddress">Email Address:</label>
        <input type="email" id="emailAddress" v-model="localEmailAddress" @input="updateField('emailAddress', localEmailAddress)" placeholder="Enter email address">
      </div>

      <!-- Additional Fields -->
      <div class="additional-fields">
        <div class="form-group additional-field" v-for="(additionalField, index) in localAdditionalFields" :key="index">
          <label :for="'additionalField-' + index">{{ additionalField.label }}:</label>
          <div class="additional-inputs">
            <input type="text" :id="'additionalField-' + index" v-model="additionalField.value" @input="updateAdditionalField(index, additionalField.value)" placeholder="Enter value">
            <input type="text" :id="'additionalField-href-' + index" v-model="additionalField.href" :placeholder="getHrefPlaceholder(additionalField.hrefType)" @input="updateAdditionalFieldHref(index, additionalField.href)">
            <button type="button" class="btn-remove" @click="removeAdditionalField(index)">✖</button>
          </div>
        </div>
        <!-- Add Field Button -->
        <button type="button" class="btn-add-field" @click="showModal">+ Add Field</button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      localName: this.formData.name,
      localJobTitle: this.formData.jobTitle,
      localPhoneNumber: this.formData.phoneNumber,
      localEmailAddress: this.formData.emailAddress,
      localAdditionalFields: [...this.additionalFields],
      localLogoImage: this.logoImage,
      profileImageHref: this.formData.profileImageHref,
      logoImageHref: this.formData.logoImageHref,
      profileImageURL: this.formData.profileImage,
      logoImageURL: this.logoImage,
      profileImageView: this.formData.profileImage,
    };
  },
  watch: {
    formData: {
      handler(newValue) {
        this.localName = newValue.name;
        this.localJobTitle = newValue.jobTitle;
        this.localPhoneNumber = newValue.phoneNumber;
        this.localEmailAddress = newValue.emailAddress;
        this.profileImageView = newValue.profileImage;
        this.profileImageHref = newValue.profileImageHref;
        this.logoImageHref = newValue.logoImageHref;
      },
      deep: true
    },
    additionalFields: {
      handler(newValue) {
        this.localAdditionalFields = [...newValue];
      },
      deep: true
    },
    logoImage(newValue) {
      this.localLogoImage = newValue;
    },
    profileImageHref(newValue) {
      this.profileImageHref = newValue;
    },
    logoImageHref(newValue) {
      this.logoImageHref = newValue;
    },
    profileImage(newValue) {
      this.profileImageView = newValue;
    }
  },
  methods: {
    updateProfileImageURL() {
      this.profileImageView = this.profileImageURL;
      this.$emit('update-image', this.profileImageURL);
    },
    updateLogoImageURL() {
      this.localLogoImage = this.logoImageURL;
      this.$emit('update-logo-image', this.logoImageURL);
    },
    updateField(fieldName, value) {
      this.$emit('update', { [fieldName]: value });
    },
    removeAdditionalField(index) {
      this.localAdditionalFields.splice(index, 1);
      this.$emit('remove-field', index);
    },
    updateAdditionalField(index, value) {
      this.localAdditionalFields[index].value = value;
      this.$emit('update-field-value', index, value);
    },
    updateAdditionalFieldHref(index, href) {
      this.localAdditionalFields[index].href = href;
      this.$emit('update-field-href', index, href);
    },
    handleLogoUpload(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = e => {
          this.localLogoImage = e.target.result;
          this.$emit('update-logo-image', this.localLogoImage);
        };
        reader.readAsDataURL(file);
      }
    },
    removeLogo() {
      this.localLogoImage = null;
      this.$emit('update-logo-image', null);
    },
    handleImageUpload(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = e => {
          this.profileImageView = e.target.result;
          this.$emit('update-image', e.target.result);
        };
        reader.readAsDataURL(file);
      }
    },
    removeImage() {
      this.profileImageView = '';
      this.$emit('update-image', '');
    },
    triggerFileInput() {
      document.getElementById('profileImage').click();
    },
    triggerLogoFileInput() {
      document.getElementById('logoImage').click();
    },
    updateProfileImageHref() {
      this.$emit('update-profile-image-href', this.profileImageHref);
    },
    updateLogoImageHref() {
      this.$emit('update-logo-image-href', this.logoImageHref);
    },
    showModal() {
      this.$emit('show-modal');
    },
    getHrefPlaceholder(hrefType) {
      switch (hrefType) {
        case 'tel':
          return 'Enter phone number';
        case 'mailto':
          return 'Enter email address';
        default:
          return 'Enter URL';
      }
    }
  },
  props: {
    formData: Object,
    additionalFields: Array,
    logoImage: String,
    mainColor: String,
    secondaryColor: String
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css');

.header {
  margin-bottom: 20px;
}

.header h2 {
  font-size: 1.5em;
  color: #555;
  border-bottom: 2px solid #eee;
  padding-bottom: 10px;
  transition: color 0.3s, border-color 0.3s;
}

.form-group {
  margin-bottom: 1.5rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
  color: #666;
}

input[type="text"]{
  transition: border-color 0.5s;
}

input[type="text"], input[type="email"] {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 1rem;
}

input[type="text"]:focus, input[type="email"]:focus {
  outline: none;
  border-color: #FF6A00;
}

.additional-inputs {
  display: flex;
  align-items: center;
  gap: 10px;
}

.logo-upload {
  margin-top: 10px;
}

.logo-upload img {
  display: block;
  margin-top: 10px;
}

.logo-upload button {
  display: block;
  margin-top: 5px;
  color: #fff;
  background-color: #f00;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.input-file {
  display: none;
}

.file-label {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  gap: 8px;
  margin-right: 1rem;
}

.file-label-content {
  display: flex;
  align-items: center;
  gap: 8px;
}

.file-icon {
  font-size: 1.5em;
  color: #2196F3;
}

.file-label span {
  flex: 1;
}

.btn {
  background-color: #2196F3;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn:hover {
  background-color: #1976D2;
}

.btn-remove {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-remove:hover {
  background-color: #d32f2f;
}

.btn-remove-field {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.btn-remove-field:hover {
  color: #d32f2f;
}

.btn-add-field {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-add-field:hover {
  background-color: #388E3C;
}

.additional-fields {
  margin-top: 2rem;
}

.image-upload {
  display: flex;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.image-preview {
  margin-right: 3rem;
  position: relative;
}

.image-preview img {
  width: 100px;
  height: 100px;
  object-fit: contain;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.image-preview .btn-remove {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
}

.dark-mode .header h2 {
  color: #ddd;
  border-bottom-color: #555;
}

.dark-mode label {
  color: #ddd;
}

.dark-mode input[type="text"], .dark-mode input[type="email"] {
  background-color: #2c2c2c;
  border-color: #555;
  color: #ddd;
}

.dark-mode input[type="text"]:focus, .dark-mode input[type="email"]:focus {
  border-color: #c15000;
}

.dark-mode .file-label {
  border-color: #555;
}

.dark-mode .btn {
  background-color: #1E88E5;
}

.dark-mode .btn:hover {
  background-color: #1565C0;
}

.dark-mode .btn-remove {
  background-color: #e53935;
}

.dark-mode .btn-remove:hover {
  background-color: #c62828;
}

.dark-mode .btn-add-field {
  background-color: #43A047;
}

.dark-mode .btn-add-field:hover {
  background-color: #2E7D32;
}

.dark-mode .image-preview .btn-remove {
  background-color: #e53935;
}

.dark-mode .image-preview .btn-remove:hover {
  background-color: #c62828;
}

body {
  transition: background 0.5s, color 0.5s;
}

body.dark-mode {
  background: #181818;
  color: #ffffff;
}
</style>
