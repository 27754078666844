<template>
  <table cellspacing="0" cellpadding="0" border="0" :width="signatureWidth" :style="{ backgroundColor: descriptionBackground, fontFamily: fontFamily }">
    <tr>
      <td v-if="showProfileImage" align="center">
        <a v-if="profileImageHref" :href="formatHref(profileImageHref)" target="_blank">
          <img :src="profileImage" 
            :width="profileImageSize" 
            :height="profileImageSize" 
            :style="{ 
              'max-width': `${profileImageSize}px`, 
              'object-fit': 'cover',
              'border-radius': `${profileImageShape}%`
            }">
        </a>
        <img v-else :src="profileImage" 
            :width="profileImageSize" 
            :height="profileImageSize" 
            :style="{ 
              'max-width': `${profileImageSize}px`, 
              'object-fit': 'cover',
              'border-radius': `${profileImageShape}%`
            }">
      </td>
      <td valign="top">
        <table cellspacing="0" cellpadding="0" border="0" style="padding: 10px">
          <tr>
            <td>
              <span :style="{ fontFamily: fontFamily, color: mainColor, fontSize: `${computedFontSize + 8}px` }">{{ name }}</span>
            </td>
          </tr>
          <tr>
            <td style="padding-top: 5px">
              <span :style="{ fontFamily: fontFamily, color: secondaryColor, fontSize: `${computedFontSize}px` }">{{ jobTitle }}</span>
            </td>
          </tr>
          <tr v-if="phoneNumber">
            <td style="padding-top: 5px">
              <a :href="`tel:${formatPhoneNumber(phoneNumber)}`" :style="{ fontFamily: fontFamily, color: secondaryColor, fontSize: `${computedFontSize}px`, textDecoration: 'none' }">
                <span :style="{ fontFamily: fontFamily, color: mainColor }">Tel: </span>{{ phoneNumber }}
              </a>
            </td>
          </tr>
          <tr v-if="emailAddress">
            <td style="padding-top: 5px">
              <a :href="`mailto:${emailAddress}`" :style="{ fontFamily: fontFamily, color: secondaryColor, fontSize: `${computedFontSize}px`, textDecoration: 'none' }">
                <span :style="{ fontFamily: fontFamily, color: mainColor }">E-mail: </span>{{ emailAddress }}
              </a>
            </td>
          </tr>
          <tr v-for="(field, index) in additionalFields" :key="index">
            <td style="padding-top: 5px">
              <span :style="{ fontFamily: fontFamily, color: mainColor, fontSize: `${computedFontSize}px` }">{{ field.label }}: </span>
              <a v-if="field.href" :href="formatHref(field.href, field.hrefType)" target="_blank" :style="{ color: secondaryColor, textDecoration: 'none', fontSize: `${computedFontSize}px` }">{{ field.value }}</a>
              <span v-else :style="{ fontFamily: fontFamily, color: secondaryColor, fontSize: `${computedFontSize}px` }">{{ field.value }}</span>
            </td>
          </tr>
          <tr v-if="feedback && feedback.question">
            <td style="padding-top: 5px">
              <table cellspacing="0" cellpadding="0" border="0">
                <tr>
                  <td>
                    <p :style="{ fontFamily: fontFamily, color: addonsColor, fontSize: `${computedFontSize}px` }">{{ feedback.question }}</p>
                  </td>
                </tr>
                <tr>
                  <td align="center" style="padding-top: 5px">
                    <a v-if="feedback.likeLink" :href="formatHref(feedback.likeLink)" target="_blank" :style="{ textDecoration: 'none' }">
                      <img src="https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:43:02.337Z_18660755-126d-41eb-827e-0b6d751fb232" width="24" height="24" style="padding-right: 5px;">
                    </a>
                    <img v-else src="https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:43:02.337Z_18660755-126d-41eb-827e-0b6d751fb232" width="24" height="24" style="padding-right: 5px;">
                    <a v-if="feedback.dislikeLink" :href="formatHref(feedback.dislikeLink)" target="_blank" :style="{ textDecoration: 'none' }">
                      <img src="https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:43:13.412Z_bd177bcd-2cea-4537-9ed9-e970eb8f3e79" width="24" height="24">
                    </a>
                    <img v-else src="https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:43:13.412Z_bd177bcd-2cea-4537-9ed9-e970eb8f3e79" width="24" height="24">
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </td>
    </tr>
    <tr>
      <td v-if="showLogo" style="padding-top: 20px" align="center">
        <a v-if="logoImageHref" :href="formatHref(logoImageHref)" target="_blank">
          <img :src="logoImage" alt="Logo" :width="logoSize" :style="{ width: `${logoSize}px`}">
        </a>
        <img v-else :src="logoImage" alt="Logo" :width="logoSize" :style="{ width: `${logoSize}px`}">
      </td>
      <td style="padding-top: 20px; padding-left: 10px;">
        <table cellspacing="0" cellpadding="0" border="0">
          <tr>
            <td v-for="icon in icons" :key="icon" style="padding-right: 5px;">
              <a v-if="socialLinks[icon]" :href="formatHref(socialLinks[icon])" target="_blank">
                <img :src="getIconPath(icon)" :alt="icon" :width="socialIconSize" :height="socialIconSize" :style="{ 'padding': '5px', 'border-radius': '5px', backgroundColor: socialIconColor }">
              </a>
              <img v-else :src="getIconPath(icon)" :alt="icon" :width="socialIconSize" :height="socialIconSize" :style="{ 'padding': '5px', 'border-radius': '5px', backgroundColor: socialIconColor }">
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
  <table v-if="link.name" :width="signatureWidth">
    <tr>
      <td style="padding-top: 10px">
        <a v-if="link.url" :href="formatHref(link.url)" target="_blank" :style="{ fontFamily: fontFamily, color: addonsColor, fontSize: `${computedFontSize}px`, textDecoration: 'none' }">
          <span :style="{ fontFamily: fontFamily, color: addonsColor }">{{ link.name }}</span>
        </a>
        <span v-else :style="{ fontFamily: fontFamily, color: addonsColor, fontSize: `${computedFontSize}px` }">
          {{ link.name }}
        </span>
      </td>
    </tr>
  </table>
  <table v-if="banner.url" cellspacing="0" cellpadding="0" border="0" :width="signatureWidth">
    <tr>
      <td style="padding-top: 10px">
        <a v-if="banner.href" :href="formatHref(banner.href)" target="_blank">
          <img :src="banner.url" :width="bannerWidth" style="max-width: 100%;">
        </a>
        <img v-else :src="banner.url" :width="bannerWidth" style="max-width: 100%;">
      </td>
    </tr>
  </table>
  <table v-if="disclaimer" cellspacing="0" cellpadding="0" border="0" :width="tableWidth">
    <tr>
      <td style="padding-top: 10px">
        <span v-html="disclaimer" :style="{ fontFamily: fontFamily, color: addonsColor, fontSize: `${computedFontSize - 2}px` }"></span>
      </td>
    </tr>
  </table>
</template>

<script>
export default {
  props: {
    name: String,
    jobTitle: String,
    phoneNumber: String,
    emailAddress: String,
    mainColor: String,
    secondaryColor: String,
    selectedIcons: Array,
    socialLinks: Object,
    iconLinks: Object,
    profileImage: String,
    profileImageHref: String,
    descriptionBackground: String,
    borderColor: String,
    signatureWidth: Number,
    showProfileImage: Boolean,
    profileImageSize: Number,
    profileImageShape: Number,
    fontFamily: String,
    fontSize: String,
    additionalFields: Array,
    template: String,
    logoImage: String,
    logoImageHref: String,
    showLogo: Boolean,
    disclaimerWidth: Boolean,
    logoSize: Number,
    socialIconColor: String,
    socialIconSize: Number,
    addonsColor: String,
    bannerWidth: Number,
    feedback: Object,
    link: {
      type: Object,
      default: () => ({})
    },
    banner: {
      type: Object,
      default: () => ({})
    },
    disclaimer: {
      type: String,
      default: ''
    },
    customIcons: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    tableWidth() {
      return this.disclaimerWidth ? '100%' : `${this.signatureWidth}px`;
    },
    icons() {
      return this.selectedIcons;
    },
    computedFontSize() {
      switch (this.fontSize) {
        case 'small':
          return 12;
        case 'medium':
          return 14;
        case 'large':
          return 16;
        default:
          return 14;
      }
    }
  },
  methods: {
    getIconPath(iconName) {
      if (this.iconLinks && this.iconLinks[iconName]) {
        return this.iconLinks[iconName];
      }
      const customIcon = this.customIcons.find(icon => icon.name === iconName);
      return customIcon ? customIcon.url : '';
    },
    formatPhoneNumber(phoneNumber) {
      return phoneNumber.replace(/\s+/g, '');
    },
    formatHref(href, hrefType) {
      if (!href) return '';
      switch (hrefType) {
        case 'tel':
          return `tel:${href}`;
        case 'mailto':
          return `mailto:${href}`;
        default:
          if (!/^https?:\/\//i.test(href)) {
            return `https://${href}`;
          }
          return href;
      }
    }
  }
};
</script>
