<template>
  <div class="modal-overlay" v-if="show" @click.self="closeModal">
    <div class="modal-content">
      <h3>Add New Field</h3>
      <form @submit.prevent="handleSubmit">
        <div class="form-group">
          <label for="label">Label:</label>
          <input type="text" id="label" v-model="newFieldLabel" class="input-field">
        </div>
        <div class="form-group">
          <label for="value">Value:</label>
          <input type="text" id="value" v-model="newFieldValue" class="input-field">
        </div>
        <div class="form-group">
          <label for="hrefType">Type href:</label>
          <div class="select-wrapper">
            <select id="hrefType" v-model="newFieldHrefType" class="input-field select-custom">
              <option value="url">URL</option>
              <option value="tel">tel:</option>
              <option value="mailto">mailto:</option>
            </select>
          </div>
        </div>
        <div class="form-group">
          <label for="href">href:</label>
          <input type="text" id="href" v-model="newFieldHref" class="input-field" :placeholder="getHrefPlaceholder">
        </div>
        <div class="form-actions">
          <button type="button" class="btn-cancel" @click="closeModal">Cancel</button>
          <button type="submit" class="btn-save">Save</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalAdd',
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      newFieldLabel: '',
      newFieldValue: '',
      newFieldHref: '',
      newFieldHrefType: 'url'
    };
  },
  computed: {
    getHrefPlaceholder() {
      switch (this.newFieldHrefType) {
        case 'tel':
          return 'Enter phone number';
        case 'mailto':
          return 'Enter email address';
        default:
          return 'Enter URL';
      }
    }
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    handleSubmit() {
      this.$emit('add-field', {
        label: this.newFieldLabel,
        value: this.newFieldValue,
        href: this.newFieldHref,
        hrefType: this.newFieldHrefType
      });
      this.newFieldLabel = '';
      this.newFieldValue = '';
      this.newFieldHref = '';
      this.newFieldHrefType = 'url';
      this.closeModal();
    }
  }
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s;
}

.modal-content {
  background: linear-gradient(135deg, #ffffff, #e0e0e0);
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
  width: 100%;
  max-width: 500px;
  max-height: 80vh;
  overflow-y: auto;
  position: relative;
  animation: slideIn 0.5s ease;
  transition: background-color 0.3s, color 0.3s;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideIn {
  from { transform: translateY(-50px); }
  to { transform: translateY(0); }
}

.form-group {
  margin-bottom: 1rem;
}

.label {
  font-weight: bold;
}

.input-field {
  box-sizing: border-box;
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  background-color: #fff;
  color: #000;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
}

.select-wrapper {
  position: relative;
}

.select-custom {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  background-color: #fff;
  background-image: url('data:image/svg+xml;utf8,<svg fill="%23777" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 16px;
}

.select-custom::-ms-expand {
  display: none;
}

.select-custom option {
  padding: 0.5rem;
  background-color: white;
  color: black;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1rem;
}

.btn-cancel {
  background-color: #f44336;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-cancel:hover {
  background-color: #d32f2f;
}

.btn-save {
  background-color: #4CAF50;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-save:hover {
  background-color: #388e3c;
}

.dark-mode .modal-content {
  background: linear-gradient(135deg, #333333, #444444);
  color: #ddd;
}

.dark-mode .input-field {
  background-color: #555;
  border-color: #777;
  color: #ddd;
}

.dark-mode .select-custom {
  background-color: #555;
  border-color: #777;
  color: #ddd;
}

.dark-mode .select-custom option {
  background-color: #555;
  color: #ddd;
}

.dark-mode .btn-cancel {
  background-color: #ff6b6b;
}

.dark-mode .btn-cancel:hover {
  background-color: #ff4c4c;
}

.dark-mode .btn-save {
  background-color: #66bb6a;
}

.dark-mode .btn-save:hover {
  background-color: #4caf50;
}
</style>
