<template>
  <!-- <div class="signature-options"> -->
    <div class="header">
      <h2>Signature Options</h2>
    </div>

    <div class="options-container">
      <!-- Main Color -->
      <div class="option">
        <label for="main-color">Main Color:</label>
        <div class="color-picker-container">
          <input type="color" id="main-color" v-model="options.mainColor" @input="updateHexColor('mainColor')" class="color-input">
          <input type="text" v-model="options.mainColor" @input="updateColor('mainColor')" maxlength="7" placeholder="#000000" class="text-input">
        </div>
      </div>

      <!-- Secondary Color -->
      <div class="option">
        <label for="secondary-color">Secondary Color:</label>
        <div class="color-picker-container">
          <input type="color" id="secondary-color" v-model="options.secondaryColor" @input="updateHexColor('secondaryColor')" class="color-input">
          <input type="text" v-model="options.secondaryColor" @input="updateColor('secondaryColor')" maxlength="7" placeholder="#000000" class="text-input">
        </div>
      </div>

      <!-- Addons Color -->
      <div class="option">
        <label for="addons-color">Addons Color:</label>
        <div class="color-picker-container">
          <input type="color" id="addons-color" v-model="options.addonsColor" @input="updateHexColor('addonsColor')" class="color-input">
          <input type="text" v-model="options.addonsColor" @input="updateColor('addonsColor')" maxlength="7" placeholder="#000000" class="text-input">
        </div>
      </div>

      <!-- Border Color -->
      <div class="option">
        <label for="border-color">Border Color:</label>
        <div class="color-picker-container">
          <input type="color" id="border-color" v-model="options.borderColor" @input="updateHexColor('borderColor')" class="color-input">
          <input type="text" v-model="options.borderColor" @input="updateColor('borderColor')" maxlength="7" placeholder="#000000" class="text-input">
          <button @click="setTransparent('borderColor')" class="transparent-button">Transparent</button>
        </div>
      </div>

      <!-- Description Background Color -->
      <div class="option">
        <label for="description-background">Background Color:</label>
        <div class="color-picker-container">
          <input type="color" id="description-background" v-model="options.descriptionBackground" @input="updateHexColor('descriptionBackground')" class="color-input">
          <input type="text" v-model="options.descriptionBackground" @input="updateColor('descriptionBackground')" maxlength="7" placeholder="#000000" class="text-input">
          <button @click="setTransparent('descriptionBackground')" class="transparent-button">Transparent</button>
        </div>
      </div>

      <!-- Social Media Icon Color -->
      <div class="option">
        <label for="social-icon-color">Social Media Icon Color:</label>
        <div class="color-picker-container">
          <input type="color" id="social-icon-color" v-model="options.socialIconColor" @input="updateHexColor('socialIconColor')" class="color-input">
          <input type="text" v-model="options.socialIconColor" @input="updateColor('socialIconColor')" maxlength="7" placeholder="#000000" class="text-input">
          <button @click="setTransparent('socialIconColor')" class="transparent-button">Transparent</button>
        </div>
      </div>

      <!-- Signature Width -->
      <div class="option">
        <label for="signature-width">Signature Width:</label>
        <div class="range-container">
          <input type="range" id="signature-width" min="200" max="800" step="10" v-model="signatureWidth" class="range-slider">
          <span class="tooltip">{{ signatureWidth }}px</span>
        </div>
      </div>

      <!-- Social Media Icon Size -->
      <div class="option">
        <label for="social-icon-size">Social Media Icon Size:</label>
        <div class="range-container">
          <input type="range" id="social-icon-size" min="16" max="36" step="2" v-model="socialIconSize" class="range-slider">
          <span class="tooltip">{{ socialIconSize }}px</span>
        </div>
      </div>

      <!-- Toggle Profile Image -->
      <div class="option toggle">
        <label for="show-profile-image">Show Profile Image:</label>
        <label class="switch">
          <input type="checkbox" id="show-profile-image" v-model="showProfileImage">
          <span class="slider round"></span>
        </label>
      </div>

      <!-- Profile Image Size -->
      <div class="option">
        <label for="profile-image-size">Profile Image Size:</label>
        <div class="range-container">
          <input type="range" id="profile-image-size" min="80" max="200" step="10" v-model="profileImageSize" class="range-slider">
          <span class="tooltip">{{ profileImageSize }}px</span>
        </div>
      </div>

      <!-- Profile Image Shape -->
      <div class="option">
        <label for="profile-image-shape">Profile Image Shape:</label>
        <div class="range-container">
          <input type="range" id="profile-image-shape" min="0" max="50" step="1" v-model="profileImageShape" class="range-slider">
          <span class="tooltip">{{ profileImageShape }}%</span>
        </div>
      </div>

      <!-- Toggle Logo -->
      <div class="option toggle">
        <label for="show-logo">Show Logo:</label>
        <label class="switch">
          <input type="checkbox" id="show-logo" v-model="showLogo">
          <span class="slider round"></span>
        </label>
      </div>

      <!-- Logo Size -->
      <div class="option">
        <label for="logo-size">Logo Size:</label>
        <div class="range-container">
          <input type="range" id="logo-size" min="80" max="200" step="5" v-model="logoSize" class="range-slider">
          <span class="tooltip">{{ logoSize }}px</span>
        </div>
      </div>

      <!-- Toggle Disclaimer Width -->
      <div class="option toggle">
        <label for="show-disclaimer">Disclaimer Full Width:</label>
        <label class="switch">
          <input type="checkbox" id="show-logo" v-model="disclaimerWidth">
          <span class="slider round"></span>
        </label>
      </div>

      <!-- Banner Width -->
      <div class="option">
        <label for="banner-width">Banner Width:</label>
        <div class="range-container">
          <input type="range" id="banner-width" min="200" max="800" step="10" v-model="bannerWidth" @input="updateBannerWidth" class="range-slider">
          <span class="tooltip">{{ bannerWidth }}px</span>
        </div>
      </div>

      <!-- Font Family -->
      <div class="option">
        <label for="font-family">Font Family:</label>
        <select id="font-family" v-model="options.fontFamily" class="select-menu">
          <option value="Arial, sans-serif">Arial</option>
          <option value="'Courier New', monospace">Courier New</option>
          <option value="'Times New Roman', serif">Times New Roman</option>
          <option value="'Georgia', serif">Georgia</option>
          <option value="Verdana, sans-serif">Verdana</option>
          <option value="'Trebuchet MS', sans-serif">Trebuchet MS</option>
          <option value="Tahoma, sans-serif">Tahoma</option>
          <option value="'Comic Sans MS', cursive">Comic Sans MS</option>
        </select>
      </div>

      <!-- Font Size -->
      <div class="option">
        <label for="font-size">Font Size:</label>
        <select id="font-size" v-model="options.fontSize" class="select-menu">
          <option value="small">Small</option>
          <option value="medium">Medium</option>
          <option value="large">Large</option>
        </select>
      </div>
    </div>
</template>

<script>
export default {
  name: 'SignatureOptions',
  props: {
    formData: Object
  },
  data() {
    return {
      defaultOptions: {
        showProfileImage: this.formData.showProfileImage,
        mainColor: this.formData.mainColor,
        secondaryColor: this.formData.secondaryColor,
        descriptionBackground: this.formData.descriptionBackground,
        socialIconColor: this.formData.socialIconColor,
        signatureWidth: this.formData.signatureWidth,
        fontFamily: this.formData.fontFamily,
        fontSize: this.formData.fontSize,
        profileImageSize: this.formData.profileImageSize,
        profileImageShape: this.formData.profileImageShape,
        showLogo: this.formData.showLogo,
        disclaimerWidth: this.formData.disclaimerWidth,
        logoSize: this.formData.logoSize,
        socialIconSize: this.formData.socialIconSize,
        borderColor: this.formData.borderColor,
      },
      showProfileImage: this.formData.showProfileImage,
      signatureWidth: this.formData.signatureWidth,
      socialIconSize: this.formData.socialIconSize,
      options: {
        mainColor: this.formData.mainColor,
        secondaryColor: this.formData.secondaryColor,
        descriptionBackground: this.formData.descriptionBackground,
        socialIconColor: this.formData.socialIconColor,
        fontFamily: this.formData.fontFamily,
        fontSize: this.formData.fontSize,
        borderColor: this.formData.borderColor,
        addonsColor: this.formData.addonsColor,
      },
      profileImageSize: this.formData.profileImageSize,
      profileImageShape: this.formData.profileImageShape,
      showLogo: this.formData.showLogo,
      disclaimerWidth: this.formData.disclaimerWidth,
      logoSize: this.formData.logoSize,
      bannerWidth: this.formData.bannerWidth,
    };
  },
  methods: {
    setTransparent(option) {
      this.options[option] = 'transparent';
      this.$emit(option + 'Change', 'transparent');
    },
    updateHexColor(option) {
      this.$emit(option + 'Change', this.options[option]);
    },
    updateColor(option) {
      const color = this.options[option];
      if (/^#[0-9A-F]{6}$/i.test(color)) {
        this.$emit(option + 'Change', color);
      }
    }
  },
  watch: {
    'options.addonsColor'(newColor) {
      this.$emit('addonsColorChange', newColor);
    },
    bannerWidth(newValue) {
      this.$emit('bannerWidthChange', newValue);
    },
    'options.mainColor'(newColor) {
      this.$emit('mainColorChange', newColor);
    },
    'options.secondaryColor'(newColor) {
      this.$emit('secondaryColorChange', newColor);
    },
    'options.borderColor'(newColor) {
      this.$emit('borderColorChange', newColor);
    },
    'options.descriptionBackground'(newColor) {
      this.$emit('descriptionBackgroundChange', newColor);
    },
    'options.socialIconColor'(newColor) {
      this.$emit('socialIconColorChange', newColor);
    },
    signatureWidth(newValue) {
      this.$emit('signatureWidthChange', newValue);
    },
    showProfileImage(newValue) {
      this.$emit('toggleProfileImage', newValue);
    },
    profileImageSize(newValue) {
      this.$emit('profileImageSizeChange', newValue);
    },
    profileImageShape(newValue) {
      this.$emit('profileImageShapeChange', newValue);
    },
    showLogo(newValue) {
      this.$emit('toggleLogo', newValue);
    },
    disclaimerWidth(newValue) {
      this.$emit('toggleDisclaimer', newValue);
    },
    logoSize(newValue) {
      this.$emit('logoSizeChange', newValue);
    },
    socialIconSize(newValue) {
      this.$emit('socialIconSizeChange', newValue);
    },
    'options.fontFamily'(newValue) {
      this.$emit('fontFamilyChange', newValue);
    },
    'options.fontSize'(newValue) {
      this.$emit('fontSizeChange', newValue);
    }
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.header {
  margin-bottom: 20px;
}

.header h2 {
  font-size: 1.5em;
  color: #555;
  border-bottom: 2px solid #eee;
  padding-bottom: 10px;
  transition: color 0.5s, border-color 0.5s;
}

.options-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.option {
  flex: 1 1 48%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.option label {
  font-weight: bold;
  margin-bottom: 5px;
  color: #666;
  transition: color 0.5s;
}

.color-picker-container {
  display: flex;
  gap: 10px;
  align-items: center;
}

.color-input {
  padding: 0;
  width: 40px;
  height: 40px;
  border: none;
  background: none;
  cursor: pointer;
}

.text-input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1em;
  background-color: #f1f1f1;
  color: #333;
  transition: background-color 0.5s, color 0.5s, border-color 0.5s;
}

.transparent-button {
  background-color: transparent;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 8px 12px;
  cursor: pointer;
  color: #666;
  font-size: 0.9em;
  transition: background-color 0.3s ease, color 0.5s, border-color 0.5s;
}

.transparent-button:hover {
  background-color: #eee;
}

.range-container {
  position: relative;
  display: flex;
  align-items: center;
}

.range-slider {
  width: 80%;
  -webkit-appearance: none;
  appearance: none;
  height: 8px;
  background: #ddd;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s, background-color 0.5s;
  border-radius: 4px;
}

.range-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: #2196F3;
  cursor: pointer;
  border-radius: 50%;
  border: 2px solid #fff;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
}

.range-slider:focus::-webkit-slider-thumb {
  background: #1e88e5;
}

.range-slider:hover {
  opacity: 1;
}

.tooltip {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  background-color: #333;
  color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.8em;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:checked + .slider:before {
  transform: translateX(20px);
}

.option select {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1em;
  background-color: #fff;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.5s, color 0.5s;
}

.option select:hover {
  background-color: #eee;
}

.text-input:focus {
  border-color: #FF6A00;
  outline: none;
}

input[type="text"]:focus {
  outline: none;
  border-color: #FF6A00;
}

.dark-mode input[type="text"]:focus {
  border-color: #c15000;
}

.select-menu {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url('data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjMDAwMDAwIiB2aWV3Qm94PSIwIDAgMjQgMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEuNCA4bDEwLjU5IDExIDEwLjU5LTExTDI0IDkuNDRsLTEyIDEyLTEyLTEyeiIvPjwvc3ZnPg==') no-repeat right 10px center;
  background-size: 12px 12px;
}

.dark-mode .header h2 {
  color: #ddd;
  border-color: #555;
}

.dark-mode .option label {
  color: #ddd;
}

.dark-mode .text-input {
  background-color: #333;
  color: #ddd;
  border-color: #555;
}

.dark-mode .transparent-button {
  color: #ddd;
  border-color: #555;
}

.dark-mode .transparent-button:hover {
  background-color: #555;
}

.dark-mode .range-slider {
  background: #555;
}

.dark-mode .range-slider::-webkit-slider-thumb {
  background: #555;
}

.dark-mode .tooltip {
  background-color: #ddd;
  color: #333;
}

.dark-mode .slider {
  background-color: #555;
}

.dark-mode .option select {
  background-color: #333;
  color: #ddd;
  border-color: #555;
}

.dark-mode .option select:hover {
  background-color: #555;
}
</style>
