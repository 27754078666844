<template>
  <div class="signature-addons">
    <div class="header">
      <h2>Signature Addons</h2>
    </div>
    <div class="options-container">
      <!-- Link Section -->
      <div class="option">
        <div class="dropdown-header" @click="toggleSection('link')">
          <span>Link</span>
          <button @click.stop="clearLink" class="btn-remove">✖</button>
        </div>
        <transition name="slide-fade">
          <div v-if="sections.link" class="addon-form">
            <div class="option">
              <label for="link-name">Link Name:</label>
              <input v-model="linkName" @input="sanitizeLinkInput" id="link-name" type="text" class="text-input" placeholder="Enter link name">
            </div>
            <div class="option">
              <label for="link-url">Link URL:</label>
              <input v-model="linkUrl" @input="sanitizeLinkInput" id="link-url" type="url" class="text-input" placeholder="Enter link URL">
            </div>
          </div>
        </transition>
      </div>
      <!-- Banner Section -->
      <div class="option">
        <div class="dropdown-header" @click="toggleSection('banner')">
          <span>Banner</span>
          <button @click.stop="clearBanner" class="btn-remove">✖</button>
        </div>
        <transition name="slide-fade">
          <div v-if="sections.banner" class="addon-form">
            <div class="option">
              <label for="banner-url">Banner URL:</label>
              <input v-model="bannerUrl" @input="sanitizeBannerInput" id="banner-url" type="url" class="text-input" placeholder="Enter banner image URL">
            </div>
            <div class="option">
              <label for="banner-href">Banner href:</label>
              <input v-model="bannerHref" @input="sanitizeBannerInput" id="banner-href" type="url" class="text-input" placeholder="Enter banner href">
            </div>
          </div>
        </transition>
      </div>
      <!-- Disclaimer Section -->
      <div class="option">
        <div class="dropdown-header" @click="toggleSection('disclaimer')">
          <span>Disclaimer</span>
          <button @click.stop="clearDisclaimer" class="btn-remove">✖</button>
        </div>
        <transition name="slide-fade">
          <div v-if="sections.disclaimer" class="addon-form">
            <div class="option">
              <label for="disclaimer">Disclaimer:</label>
              <textarea v-model="disclaimer" @input="sanitizeDisclaimerInput" id="disclaimer" class="text-input" placeholder="Enter disclaimer Text or HTML"></textarea>
            </div>
          </div>
        </transition>
      </div>
      <!-- Feedback Section -->
      <div class="option">
        <div class="dropdown-header" @click="toggleSection('feedback')">
          <span>Feedback</span>
          <button @click.stop="clearFeedback" class="btn-remove">✖</button>
        </div>
        <transition name="slide-fade">
          <div v-if="sections.feedback" class="addon-form">
            <div class="option">
              <label for="question">Question:</label>
              <input v-model="feedbackQuestion" @input="sanitizeFeedbackInput" id="question" type="text" class="text-input" placeholder="Enter your question here">
            </div>
            <div class="option">
              <label for="like-link">Link to form:</label>
              <div class="input-icon">
                <div class="icon-container">
                  <img src="https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:43:02.337Z_18660755-126d-41eb-827e-0b6d751fb232" class="icon-square">
                </div>
                <input v-model="feedbackLikeLink" @input="sanitizeFeedbackInput" id="like-link" type="text" class="text-input with-icon" placeholder="Enter the link for like">
              </div>
            </div>
            <div class="option">
              <label for="dislike-link">Link to form:</label>
              <div class="input-icon">
                <div class="icon-container">
                  <img src="https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:43:13.412Z_bd177bcd-2cea-4537-9ed9-e970eb8f3e79" class="icon-square">
                </div>
                <input v-model="feedbackDislikeLink" @input="sanitizeFeedbackInput" id="dislike-link" type="text" class="text-input with-icon" placeholder="Enter the link for dislike">
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    formData: Object,
  },
  data() {
    return {
      sections: {
        link: false,
        banner: false,
        disclaimer: false,
        feedback: false,
      },
      linkName: this.formData.link?.name || '',
      linkUrl: this.formData.link?.url || '',
      bannerUrl: this.formData.banner?.url || '',
      bannerHref: this.formData.banner?.href || '',
      disclaimer: this.formData.disclaimer || '',
      feedbackQuestion: this.formData.feedback?.question || '',
      feedbackLikeLink: this.formData.feedback?.likeLink || '',
      feedbackDislikeLink: this.formData.feedback?.dislikeLink || '',
    };
  },
  methods: {
    emitFeedbackUpdate() {
      this.$emit('update-feedback', {
        question: this.feedbackQuestion,
        likeLink: this.feedbackLikeLink,
        dislikeLink: this.feedbackDislikeLink
      });
    },
    toggleSection(section) {
      this.sections[section] = !this.sections[section];
    },
    sanitizeInput(input) {
      return input.replace(/\s+/g, ' ').trim();
    },
    sanitizeLinkInput() {
      this.linkName = this.sanitizeInput(this.linkName);
      this.linkUrl = this.sanitizeInput(this.linkUrl);
      this.emitLinkUpdate();
    },
    sanitizeBannerInput() {
      this.bannerUrl = this.sanitizeInput(this.bannerUrl);
      this.bannerHref = this.sanitizeInput(this.bannerHref);
      this.emitBannerUpdate();
    },
    sanitizeDisclaimerInput() {
      this.disclaimer = this.sanitizeInput(this.disclaimer);
      this.emitDisclaimerUpdate();
    },
    sanitizeFeedbackInput() {
      this.feedbackQuestion = this.sanitizeInput(this.feedbackQuestion);
      this.feedbackLikeLink = this.sanitizeInput(this.feedbackLikeLink);
      this.feedbackDislikeLink = this.sanitizeInput(this.feedbackDislikeLink);
      this.emitFeedbackUpdate();
    },
    emitLinkUpdate() {
      this.$emit('update-link', { name: this.linkName, url: this.linkUrl });
    },
    emitBannerUpdate() {
      this.$emit('update-banner', { url: this.bannerUrl, href: this.bannerHref });
    },
    emitDisclaimerUpdate() {
      this.$emit('update-disclaimer', this.disclaimer);
    },
    clearLink() {
      this.linkName = '';
      this.linkUrl = '';
      this.emitLinkUpdate();
    },
    clearBanner() {
      this.bannerUrl = '';
      this.bannerHref = '';
      this.emitBannerUpdate();
    },
    clearDisclaimer() {
      this.disclaimer = '';
      this.emitDisclaimerUpdate();
    },
    clearFeedback() {
      this.feedbackQuestion = '';
      this.feedbackLikeLink = '';
      this.feedbackDislikeLink = '';
      this.emitFeedbackUpdate();
    },
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.header {
  margin-bottom: 20px;
}

.header h2 {
  font-size: 1.5em;
  color: #555;
  border-bottom: 2px solid #eee;
  padding-bottom: 10px;
  transition: color 0.3s, border-color 0.3s;
}

.options-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.option {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.dropdown-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f1f1f1;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dropdown-header:hover {
  background-color: #ddd;
}

.btn-remove {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 8px;
  transition: background-color 0.3s;
}

.btn-remove:hover {
  background-color: #d32f2f;
}

.addon-form {
  margin-top: 10px;
}

.option label {
  font-weight: bold;
  margin-bottom: 5px;
  color: #666;
  transition: color 0.5s;
}

.text-input, textarea {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1em;
  background-color: #f1f1f1;
  color: #333;
  transition: background-color 0.5s, color 0.5s, border-color 0.5s;
}

.icon-container{
  transition: all 0.5s;
}

.text-input {
  margin-bottom: 10px;
}

textarea {
  resize: vertical;
  min-height: 80px;
}

.input-icon {
  display: flex;
  align-items: center;
}

.input-icon .icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  background-color: #f1f1f1;
  border: 1px solid #ddd;
  border-radius: 4px 0 0 4px;
  margin-bottom: 10px;
}

.input-icon img.icon-square {
  width: 24px;
  height: 24px;
}

.with-icon {
  flex: 1;
  border-radius: 0 4px 4px 0;
  margin-left: -1px;
}

.transparent-button {
  background-color: transparent;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 8px 12px;
  cursor: pointer;
  color: #666;
  font-size: 0.9em;
  transition: background-color 0.3s ease, color 0.5s, border-color 0.5s;
  align-self: flex-end;
}

.transparent-button:hover {
  background-color: #eee;
}

.text-input:focus {
  border-color: #FF6A00;
  outline: none;
}

input[type="text"]:focus {
  outline: none;
  border-color: #FF6A00;
}

.dark-mode input[type="text"]:focus {
  border-color: #c15000;
}

.dark-mode .header h2 {
  color: #ddd;
  border-color: #555;
}

.dark-mode .option label {
  color: #ddd;
}

.dark-mode .text-input, .dark-mode textarea, .dark-mode .icon-container {
  background-color: #333;
  color: #ddd;
  border-color: #555;
}

.dark-mode .transparent-button {
  color: #ddd;
  border-color: #555;
}

.dark-mode .transparent-button:hover {
  background-color: #555;
}

.dark-mode .dropdown-header {
  background-color: #444;
  border-color: #555;
  color: #ddd;
}

.dark-mode .dropdown-header:hover {
  background-color: #555;
}

.dark-mode .clear-icon {
  color: #ddd;
}

.dark-mode .clear-icon:hover {
  color: #fff;
}
</style>
