<template>
  <div :class="['positive-page', { 'dark-mode': isDarkMode }]">
    <div class="feedback-form">
      <div class="dark-mode-toggle" @click="toggleDarkMode">
        <i :class="isDarkMode ? 'fas fa-sun' : 'fas fa-moon'"></i>
      </div>
      <h2>We appreciate your feedback!</h2>
      <form @submit.prevent="submitFeedback">
        <div class="comments-section">
          <label for="comments">Feel free to leave a comment</label>
          <textarea id="comments" v-model="comments" placeholder="Your comments..."></textarea>
        </div>
        <div class="recommendation">
          <label>Would you recommend our services to your friends?</label>
          <div class="rating">
            <div class="rating-labels">
              <span class="left-label">Not at all likely</span>
              <span class="right-label">Extremely likely</span>
            </div>
            <div class="rating-numbers">
              <span v-for="n in 11" :key="n-1" class="rating-item">
                <input type="radio" :id="'rate' + (n-1)" name="rating" :value="n-1" v-model="rating" @keydown.enter.prevent="rating = n-1">
                <label :for="'rate' + (n-1)" :class="{ selected: rating === (n-1) }" :style="getLabelStyle(n-1)" :title="'Rating: ' + (n-1)">{{ n-1 }}</label>
              </span>
            </div>
          </div>
        </div>
        <button type="submit" class="submit-button">Send</button>
        <div v-if="feedbackSent" class="feedback-bubble success">
          <i class="fas fa-check-circle"></i>
          <p>Thank you for your feedback!</p>
        </div>
        <div v-if="feedbackError" class="feedback-bubble error">
          <i class="fas fa-times-circle"></i>
          <p>Please provide feedback.</p>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      comments: '',
      rating: null,
      isDarkMode: false,
      feedbackSent: false,
      feedbackError: false,
    };
  },
  methods: {
    submitFeedback() {
      if (this.comments || this.rating !== null) {
        console.log('Comments:', this.comments);
        console.log('Rating:', this.rating);
        this.feedbackSent = true;
        this.feedbackError = false;
        setTimeout(() => {
          this.feedbackSent = false;
          this.comments = '';
          this.rating = null;
        }, 2000);
      } else {
        this.feedbackError = true;
        setTimeout(() => {
          this.feedbackError = false;
        }, 2000);
      }
    },
    getLabelStyle(index) {
      const hue = (index / 10) * 120;
      return {
        '--label-bg': `hsl(${hue}, 100%, 50%)`,
        '--label-border': `hsl(${hue}, 100%, 30%)`,
      };
    },
    toggleDarkMode() {
      this.isDarkMode = !this.isDarkMode;
    },
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css');

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  background: #f4f4f9;
  color: #333;
  transition: color 0.5s;
  height: 100%;
}

.positive-page {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #ece9e6, #ffffff);
  padding: 20px;
  transition: background 0.5s, color 0.5s;
}

.positive-page.dark-mode {
  background: #181818;
  color: #fff;
}

.feedback-form {
  background: #fff;
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 100%;
  text-align: center;
  animation: fadeIn 1s ease-in-out;
  position: relative;
  overflow: hidden;
  opacity: 0;
  animation: fadeInToCenter 1s forwards;
}

@keyframes fadeInToCenter {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.positive-page.dark-mode .feedback-form {
  background: #2c2c2c;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
}

.dark-mode-toggle {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  font-size: 24px;
  color: #333;
}

.positive-page.dark-mode .dark-mode-toggle {
  color: #fff;
}

.fa-moon:hover, .fa-sun:hover {
  transform: scale(1.1);
}

.feedback-form h2 {
  font-size: 2rem;
  color: #007aff;
  margin-bottom: 20px;
}

.comments-section {
  margin-bottom: 20px;
}

.comments-section label {
  display: block;
  font-weight: 500;
  margin-bottom: 10px;
  color: #666;
}

.positive-page.dark-mode .comments-section label {
  color: #ddd;
}

.comments-section textarea {
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  height: 100px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 16px;
  resize: vertical;
  transition: border-color 0.3s;
}

.comments-section textarea:focus {
  border-color: #007aff;
  outline: none;
}

.positive-page.dark-mode .comments-section textarea {
  background: #3c3c3c;
  color: #ddd;
  border-color: #555;
}

.recommendation {
  margin-top: 20px;
}

.recommendation label {
  font-weight: 500;
  color: #666;
  display: block;
  margin-bottom: 10px;
}

.positive-page.dark-mode .recommendation label {
  color: #ddd;
}

.rating {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rating-labels {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 14px;
  color: #555;
}

.positive-page.dark-mode .rating-labels {
  color: #bbb;
}

.rating-numbers {
  display: flex;
  gap: 8px !important;
  flex-wrap: wrap;
  justify-content: center;
}

.rating-item {
  position: relative;
}

.rating-numbers input {
  display: none;
}

.rating-numbers label {
  width: 45px !important;
  height: 45px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 8px;
  cursor: pointer;
  background: #f4f4f9;
  transition: background 0.3s, border-color 0.3s, color 0.3s, transform 0.3s !important;
}

.rating-numbers label:hover {
  transform: scale(1.1);
  color: #666 !important;
  transition: transform 0.3s, color 0.3s;
}

.rating-numbers input:checked + label {
  transform: scale(1.1);
  transition: transform 0.3s;
  color: #666;
}

.rating-numbers label:hover,
.rating-numbers label.selected {
  background-color: var(--label-bg);
  border-color: var(--label-border);
  color: #666;
}

.positive-page.dark-mode .rating-numbers label {
  background: #3c3c3c;
  border-color: #555;
}

.positive-page.dark-mode .rating-numbers label:hover,
.positive-page.dark-mode .rating-numbers label.selected {
  background-color: var(--label-bg);
  border-color: var(--label-border);
  color: #2b2b2b !important;
}

.submit-button {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #007aff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  margin-top: 20px;
}

.submit-button:hover {
  background-color: #005bb5;
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.feedback-bubble {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px 40px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  z-index: 1000;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  animation: fadeIn 0.5s, fadeOut 0.5s 1.5s;
}

.feedback-bubble.success {
  background: rgba(40, 167, 69, 0.9);
}

.feedback-bubble.error {
  background: rgba(220, 53, 69, 0.9);
}

.feedback-bubble i {
  font-size: 1.5rem;
  color: #fff;
}

.feedback-bubble p {
  color: #fff;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(-50%, -60%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
  to {
    opacity: 0;
    transform: translate(-50%, -60%);
  }
}

@media (max-width: 768px) {
  .feedback-form {
    padding: 20px;
  }
  
  .rating-numbers label {
    width: 35px;
    height: 35px;
  }
}

@media (max-width: 480px) {
  .positive-page {
    padding: 10px;
  }
  
  .feedback-form h2 {
    font-size: 1.5rem;
  }

  .rating-labels {
    font-size: 12px;
  }

  .rating-numbers label {
    width: 30px;
    height: 30px;
  }

  .dark-mode-toggle {
    font-size: 20px;
  }

  .submit-button {
    font-size: 14px;
    padding: 8px 16px;
  }
}
</style>
