<template>
  <div v-if="show" class="modal-overlay" @click.self="$emit('close')">
    <div class="modal-content">
      <div class="modal-header">
        <h2>Save Your Signature</h2>
        <button class="close-button" @click="$emit('close')">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="close-icon">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
          </svg>
        </button>
      </div>
      <div class="form-container">
        <div v-if="isLoading" class="loader-container">
          <LoaderApp :isLoading="true" />
        </div>
        <div class="link-container" v-else>
          <div v-if="linksGenerated">
            <div class="link-item">
              <label for="editLink">Edit Link:</label>
              <div class="link-input-container">
                <input :value="editLink" readonly class="link-input" />
                <button class="copy-button" @click="handleCopyEditLink">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="copy-icon">
                    <path d="M16 8V6a2 2 0 00-2-2H6a2 2 0 00-2 2v10a2 2 0 002 2h2v2H6a4 4 0 01-4-4V6a4 4 0 014-4h8a4 4 0 014 4v2h-2zm3 2H9a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2V12a2 2 0 00-2-2zm-8 10v-2h6v2H11z"/>
                  </svg>
                </button>
              </div>
            </div>

            <div class="link-item">
              <label for="previewLink">Preview Link:</label>
              <div class="link-input-container">
                <input :value="previewLink" readonly class="link-input" />
                <button class="copy-button" @click="handleCopyPreviewLink">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="copy-icon">
                    <path d="M16 8V6a2 2 0 00-2-2H6a2 2 0 00-2 2v10a2 2 0 002 2h2v2H6a4 4 0 01-4-4V6a4 4 0 014-4h8a4 4 0 014 4v2h-2zm3 2H9a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2V12a2 2 0 00-2-2zm-8 10v-2h6v2H11z"/>
                  </svg>
                </button>
              </div>
            </div>
          </div>

          <div v-else>
            <label for="linkNameInput" class="form-label">Enter a name for your personalized link:</label>
            <div class="name-input-container">
              <input v-model="linkName" type="text" placeholder="Enter a link name" class="name-input" id="linkNameInput" required />
              <p v-if="linkNameError" class="error-message">{{ linkNameError }}</p>
            </div>

            <label>Enter your email to receive the personalized link to your signature settings:</label>
            <div class="email-input-container">
              <input v-model="email" type="email" placeholder="Enter your email" class="email-input" @keydown.enter="sendEmail" />
              <button class="action-button" @click="sendEmail">Send Email</button>
            </div>
            <p v-if="emailError" class="error-message">{{ emailError }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="showToast" class="toast">{{ toastMessage }}</div>
</template>

<script>
import LoaderApp from './LoaderApp.vue';
import { useRouter } from 'vue-router';
import axios from 'axios';
import { BASE_URL } from '../config';

export default {
  props: ['show', 'formData'],
  components: {
    LoaderApp,
  },
  data() {
    return {
      email: '',
      linkName: '',
      originalLinkName: '',
      editLink: '',
      previewLink: '',
      showToast: false,
      toastMessage: '',
      linksGenerated: false,
      emailError: '',
      linkNameError: '',
      isLoading: false,
    };
  },
  setup() {
    const router = useRouter();
    return { basePath: router.options.history.base };
  },
  methods: {
    validateEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    },
    validateLinkName(name) {
      const re = /^[a-zA-Z0-9.]+$/;
      return re.test(name);
    },
    async generateLinks() {
      this.isLoading = true;
      try {
        const response = await axios.post(`${BASE_URL}save`, {
          settings: this.formData,
          email: this.email,
          linkName: this.linkName || null,
          originalLinkName: this.originalLinkName || null,
        });

        const data = response.data;

        if (data.error) {
          this.linkNameError = data.error;
          this.linkName = this.originalLinkName;
          this.isLoading = false;
          return;
        }

        const id = data.id;
        const domain = window.location.origin;
        const basePath = this.basePath.endsWith('/') ? this.basePath : `${this.basePath}/`;
        this.editLink = `${domain}${basePath}settings/${id}`;
        this.previewLink = `${domain}${basePath}preview/${id}`;
        this.linksGenerated = true;
        this.showToastMessage(`Links generated and email sent to ${this.email}`);
      } catch (error) {
        console.error('Error generating links:', error);
        this.showToastMessage('Error generating links. Please try again.');
      } finally {
        this.isLoading = false;
      }
    },
    async sendEmail() {
    if (!this.linkName) {
      this.linkNameError = 'Link name is required.';
    } else if (!this.validateLinkName(this.linkName)) {
      this.linkNameError = 'Link name must be one word containing only letters, numbers, and dots.';
    } else {
      this.linkNameError = '';
    }

    if (!this.validateEmail(this.email)) {
      this.emailError = 'Please enter a valid email address.';
    } else {
      this.emailError = '';
    }

    if (!this.linkNameError && !this.emailError) {
      try {
        const response = await axios.get(`${BASE_URL}check-link-name`, {
          params: { linkName: this.linkName },
        });

        if (!response.data.isAvailable) {
          this.linkNameError = 'The link name is already taken. Please choose a different name.';
        } else {
          this.linkNameError = '';
          this.originalLinkName = this.linkName;
          const modifiedLinkName = this.linkName.replace(/\./g, '-');
          this.linkName = modifiedLinkName;
          this.generateLinks();
        }
      } catch (error) {
        console.error('Error checking link name availability:', error);
        this.showToastMessage('Error checking link name availability. Please try again.');
      }
    }
  },
    handleCopyEditLink() {
      if (!this.editLink) {
        this.generateLinks();
      }
      this.copyToClipboard(this.editLink);
    },
    handleCopyPreviewLink() {
      if (!this.previewLink) {
        this.generateLinks();
      }
      this.copyToClipboard(this.previewLink);
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(() => {
        this.showToastMessage('Link copied to clipboard!');
      });
    },
    showToastMessage(message) {
      this.toastMessage = message;
      this.showToast = true;
      setTimeout(() => {
        this.showToast = false;
      }, 3000);
    },
    resetModal() {
      this.email = '';
      this.linkName = '';
      this.originalLinkName = '';
      this.editLink = '';
      this.previewLink = '';
      this.linksGenerated = false;
      this.showToast = false;
      this.toastMessage = '';
      this.emailError = '';
      this.linkNameError = '';
      this.isLoading = false;
    },
  },
  watch: {
    show(newVal) {
      if (!newVal) {
        this.resetModal();
      }
    },
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9000;
  animation: fadeIn 0.3s;
}

.modal-content {
  background: linear-gradient(135deg, #ffffff, #e0e0e0);
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
  width: 90%;
  max-width: 600px;
  max-height: 80vh;
  overflow-y: auto;
  position: relative;
  animation: slideIn 0.5s ease;
  transition: background-color 0.3s, color 0.3s;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideIn {
  from { transform: translateY(-50px); }
  to { transform: translateY(0); }
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #ddd;
  margin-bottom: 20px;
}

h2 {
  margin: 0;
  font-size: 26px;
  color: #333;
}

.close-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  transition: transform 0.3s ease;
}

.close-button:hover {
  transform: scale(1.2);
}

.close-icon {
  width: 24px;
  height: 24px;
  color: #555;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}

.link-container {
  margin-top: 20px;
  width: 100%;
  box-sizing: border-box;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.link-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
  width: 100%;
}

.link-item label {
  margin-bottom: 5px;
  font-weight: bold;
}

.link-input-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.link-input {
  flex: 1;
  padding: 12px;
  border: 2px solid #ccc;
  border-radius: 8px;
  margin-right: 10px;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

.link-input:focus,
.email-input:focus,
.name-input:focus {
  border-color: #333;
}

.copy-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  transition: transform 0.3s ease;
}

.copy-button:hover {
  transform: scale(1.2);
}

.copy-icon {
  width: 24px;
  height: 24px;
  color: #555;
}

.email-input-container {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  width: 100%;
  box-sizing: border-box;
}

.email-input {
  border: 2px solid #ccc;
  border-radius: 8px;
  width: 100%;
  font-size: 16px;
  margin-right: 10px;
  padding: 12px;
  flex-grow: 1;
}

.name-input-container {
  margin-bottom: 20px;
  width: 100%;
  box-sizing: border-box;
}

.name-input {
  border: 2px solid #ccc;
  border-radius: 8px;
  width: 100%;
  font-size: 16px;
  padding: 12px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  margin-top: 0.5rem;
  box-sizing: border-box;
}

.name-input:focus {
  border-color: #333;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.action-button {
  width: 150px;
  border: 2px solid transparent;
  border-radius: 8px;
  background-color: #333;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.action-button:hover {
  background-color: #4a4a4a;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.toast {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  animation: fadeIn 0.3s, fadeOut 0.3s 2.7s;
  z-index: 9001;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

.dark-mode .modal-content {
  background: linear-gradient(135deg, #2e2e2e, #4a4a4a);
  color: #ddd;
}

.dark-mode .modal-header {
  border-bottom-color: #555;
}

.dark-mode h2 {
  color: #fff;
}

.dark-mode .close-icon {
  color: #bbb;
}

.dark-mode .link-input {
  border-color: #555;
  background-color: #333;
  color: #ddd;
}

.dark-mode .copy-icon {
  color: #bbb;
}

.dark-mode .email-input,
.dark-mode .name-input {
  border-color: #555;
  background-color: #333;
  color: #ddd;
}

.dark-mode .action-button {
  background-color: #555;
  color: #ddd;
}

.dark-mode .action-button:hover {
  background-color: #666;
}

.dark-mode .toast {
  background-color: #555;
}
</style>
