import { createRouter, createWebHistory } from 'vue-router';
import MainApp from '../views/MainApp.vue';
import BasicInfo from '../components/BasicInfo.vue';
import SocialMedia from '../components/SocialMedia.vue';
import SignatureOptions from '../components/SignatureOptions.vue';
import SignatureTemplates from '../components/SignatureTemplates.vue';
import SignatureAddons from '../components/SignatureAddons.vue';
import FeedbackPositive from '../views/FeedbackPositive.vue';
import FeedbackNegative from '../views/FeedbackNegative.vue';
import AdminApp from '../views/AdminApp.vue';

const routes = [
  {
    path: '/',
    component: MainApp,
    children: [
      {
        path: '',
        redirect: '/signature-templates',
      },
      {
        path: '/basic-info',
        component: BasicInfo,
        meta: { title: 'Basic Info | gSignature e-mail signatures' },
      },
      {
        path: '/signature-templates',
        component: SignatureTemplates,
        meta: { title: 'Templates | gSignature e-mail signatures' },
      },
      {
        path: '/signature-options',
        component: SignatureOptions,
        meta: { title: 'Options | gSignature e-mail signatures' },
      },
      {
        path: '/social-media',
        component: SocialMedia,
        meta: { title: 'Social Media | gSignature e-mail signatures' },
      },
      {
        path: '/signature-addons',
        component: SignatureAddons,
        meta: { title: 'Addons | gSignature e-mail signatures' },
      },
    ],
  },
  {
    path: '/preview/:id',
    component: MainApp,
    meta: { title: 'Preview | gSignature e-mail signatures' },
  },
  {
    path: '/settings/:id',
    component: MainApp,
    meta: { title: 'Settings | gSignature e-mail signatures' },
  },
  {
    path: '/dev/:id',
    component: MainApp,
    meta: { title: 'Developer | gSignature e-mail signatures' },
  },
  { 
    path: '/positive',
    component: FeedbackPositive,
    meta: { title: 'Feedback Positive | gSignature e-mail signatures' },
  },
  { 
    path: '/negative',
    component: FeedbackNegative,
    meta: { title: 'Feedback Negative | gSignature e-mail signatures' },
  },
  { 
    path: '/admin',
    component: AdminApp,
    meta: { title: 'Admin | gSignature e-mail signatures' },
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },
];

const router = createRouter({
  history: createWebHistory(''),
  routes
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'Generator | gSignature e-mail signatures';
  next();
});

export default router;
