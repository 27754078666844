<template>
  <div v-if="show" class="modal-overlay" @click.self="$emit('close')">
    <div class="modal-content">
      <div class="modal-header">
        <h2>Email Signature Code</h2>
        <button class="close-button" @click="$emit('close')">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="close-icon">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
          </svg>
        </button>
      </div>
      <div class="button-container">
        <button class="action-button" @click="copyCode">Copy Code</button>
        <button class="action-button" @click="downloadCode">Download Code</button>
      </div>
      <pre class="code-container"><code ref="codeContent">{{ code }}</code></pre>
    </div>
  </div>
  <div v-if="showToast" class="toast">Code copied to clipboard!</div>
</template>

<script>
export default {
  props: {
    show: Boolean,
    code: String,
  },
  data() {
    return {
      showToast: false,
    };
  },
  methods: {
    copyCode() {
      const codeContent = this.$refs.codeContent;
      const range = document.createRange();
      range.selectNode(codeContent);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);

      try {
        document.execCommand('copy');
        this.showToast = true;
        setTimeout(() => {
          this.showToast = false;
        }, 1000);
      } catch (err) {
        alert('Failed to copy code');
      }

      window.getSelection().removeAllRanges();
    },
    downloadCode() {
      const blob = new Blob([this.code], { type: 'text/plain;charset=utf-8' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'email_signature_code.txt';
      a.click();
      URL.revokeObjectURL(url);
    }
  }
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s;
}

.modal-content {
  background: linear-gradient(135deg, #ffffff, #e0e0e0);
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
  max-width: 900px;
  max-height: 80vh;
  overflow-y: auto;
  position: relative;
  animation: slideIn 0.5s ease;
  transition: background-color 0.3s, color 0.3s;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideIn {
  from { transform: translateY(-50px); }
  to { transform: translateY(0); }
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #ddd;
  margin-bottom: 20px;
}

h2 {
  margin: 0;
  font-size: 26px;
  color: #333;
}

.close-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  transition: transform 0.3s ease;
}

.close-button:hover {
  transform: scale(1.2);
}

.close-icon {
  width: 24px;
  height: 24px;
  color: #555;
}

.button-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.action-button {
  margin: 0 10px;
  padding: 12px 24px;
  border: 2px solid transparent;
  border-radius: 8px;
  background-color: #333;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
}

.action-button:hover {
  background-color: #4a4a4a;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.code-container {
  background: #333;
  padding: 20px;
  border-radius: 10px;
  color: #f8f8f2;
  font-size: 14px;
  overflow-x: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.toast {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #333;
  color: white;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  z-index: 2000;
  animation: fadeInOut 1s;
}

@keyframes fadeInOut {
  0% { opacity: 0; }
  10% { opacity: 1; }
  90% { opacity: 1; }
  100% { opacity: 0; }
}

.dark-mode .modal-content {
  background: linear-gradient(135deg, #333333, #444444);
  color: #ddd;
}

.dark-mode .modal-header {
  border-bottom-color: #555;
}

.dark-mode h2 {
  color: #ddd;
}

.dark-mode .close-icon {
  color: #ddd;
}

.dark-mode .action-button {
  background-color: #555;
  color: #ddd;
}

.dark-mode .action-button:hover {
  background-color: #666;
}

.dark-mode .code-container {
  background: #444;
  color: #f8f8f2;
}

.dark-mode .toast {
  background-color: #444;
  color: #ddd;
}

.modal-overlay {
  overflow: scroll;
  scrollbar-width: thin;
  scrollbar-color: #c0c0c0 #f0f0f0;
}

.modal-overlay::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

.modal-overlay::-webkit-scrollbar-track {
  background: #f0f0f0;
  border-radius: 6px;
}

.modal-overlay::-webkit-scrollbar-thumb {
  background-color: #c0c0c0;
  border-radius: 6px;
  border: 3px solid #f0f0f0;
}

.modal-overlay::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a0;
}

.dark-mode .modal-overlay {
  scrollbar-color: #6b6b6b #2c2c2c;
}

.dark-mode .modal-overlay::-webkit-scrollbar-track {
  background: #2c2c2c;
}

.dark-mode .modal-overlay::-webkit-scrollbar-thumb {
  background-color: #6b6b6b;
  border: 3px solid #2c2c2c;
}

.dark-mode .modal-overlay::-webkit-scrollbar-thumb:hover {
  background-color: #4c4c4c;
}
</style>
