<template>
<div v-if="show" class="modal-overlay" @click.self="$emit('close')">
    <div class="modal-content">
    <div class="modal-header">
        <h2>Reset Settings</h2>
    </div>
    <div class="modal-body">
        <p>Are you sure you want to reset all settings?</p>
    </div>
    <div class="modal-footer button-container">
        <button class="action-button" @click="$emit('confirm')">Yes</button>
        <button class="action-button" @click="$emit('close')">No</button>
    </div>
    </div>
</div>
</template>

<script>
export default {
props: {
    show: Boolean,
},
};
</script>

<style scoped>
.modal-overlay {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background: rgba(0, 0, 0, 0.8);
display: flex;
justify-content: center;
align-items: center;
z-index: 1000;
animation: fadeIn 0.3s;
}

.modal-content {
background: linear-gradient(135deg, #ffffff, #e0e0e0);
padding: 30px;
border-radius: 20px;
box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
max-width: 900px;
max-height: 80vh;
overflow-y: auto;
position: relative;
animation: slideIn 0.5s ease;
transition: background-color 0.3s, color 0.3s;
}

@keyframes fadeIn {
from { opacity: 0; }
to { opacity: 1; }
}

@keyframes slideIn {
from { transform: translateY(-50px); }
to { transform: translateY(0); }
}

.modal-header {
display: flex;
justify-content: space-between;
align-items: center;
border-bottom: 2px solid #ddd;
margin-bottom: 20px;
}

h2 {
margin: 0;
font-size: 26px;
color: #333;
}

.close-button {
background: none;
border: none;
cursor: pointer;
padding: 10px;
transition: transform 0.3s ease;
}

.close-button:hover {
transform: scale(1.2);
}

.close-icon {
width: 24px;
height: 24px;
color: #555;
}

.button-container {
display: flex;
justify-content: center;
}

.action-button {
margin: 0 10px;
padding: 12px 24px;
border: 2px solid transparent;
border-radius: 8px;
background-color: #333;
color: white;
font-size: 16px;
cursor: pointer;
transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
}

.action-button:hover {
background-color: #4a4a4a;
transform: translateY(-2px);
box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.modal-body {
padding-bottom: 20px;
}

.modal-footer {
display: flex;
gap: 10px;
}

.dark-mode .modal-content {
background: linear-gradient(135deg, #333333, #444444);
color: #ddd;
}

.dark-mode .modal-header {
border-bottom-color: #555;
}

.dark-mode h2 {
color: #ddd;
}

.dark-mode .close-icon {
color: #ddd;
}

.dark-mode .action-button {
background-color: #555;
color: #ddd;
}

.dark-mode .action-button:hover {
background-color: #666;
}
</style>
  